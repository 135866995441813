export interface InitiateParams {
  phoneNumber: string;
}

export interface AuthOtpResponse {
  challengeKind: string;
  challengeParameters: {
    delaySec: string;
    expiresAt: string;
    isNew: boolean;
  };
}

export interface AuthSuccessResponse {}

export function isOtpResponse(
  response: AuthOtpResponse | AuthSuccessResponse
): response is AuthOtpResponse {
  return typeof (response as AuthOtpResponse)?.challengeKind === 'string';
}

export function isSuccessResponse(
  response: unknown
): response is AuthOtpResponse {
  return !isOtpResponse(response as AuthOtpResponse | AuthSuccessResponse);
}

export interface RespondParams {
  answer: string;
}

export type ValidateResponse = {
  auth: string; // Format: '2022-09-16T11:35:18.596730916Z';
  refresh: string; // Format: '2022-09-16T11:41:18.596730916Z';
  exp: string; // Format '2022-09-16T11:47:18.596730916Z';
  refreshRemainingMinutes: number;
  expRemainingMinutes: number;
};

export interface AuthApi {
  initiate(params: InitiateParams): Promise<AuthOtpResponse>;
  respond(params: RespondParams): Promise<AuthSuccessResponse>;
  resendConfirmation(): Promise<AuthOtpResponse>;
  validate(): Promise<ValidateResponse>;
  revoke(): Promise<AuthSuccessResponse>;
}
