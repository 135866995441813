import React from 'react';
import type { FieldErrors, Validate } from 'react-hook-form';
import { useForm, FormProvider } from 'react-hook-form';

import type { PhoneType } from '~entities/person';

import type { YesNoOptionValue } from '~shared/constants/yes-no-options';
import { useFormInitialize } from '~shared/hooks';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

import { CompanyDetails } from './company-details';
import { CompanyReference } from './company-reference';

import employmentImage from '../../../images/employment.webp';
import type {
  EmployedWithCompanyStatusValue,
  ReferenceBusinessValue,
  ReferenceEmployeeValue,
  ReferenceSelfEmployedValue,
} from '../../lib';
import { shouldAskForReference } from '../../lib';

type ReferenceValue =
  | ReferenceEmployeeValue
  | ReferenceBusinessValue
  | ReferenceSelfEmployedValue;

export type FormValues = {
  haveEmployees?: YesNoOptionValue;
  companyName?: string;
  reference?: ReferenceValue;
  referenceName?: string;
  workPhone?: string;
};

type Props = Readonly<{
  employmentStatus: EmployedWithCompanyStatusValue;
  initialValue?: Partial<FormValues>;
  validatePhoneUnicity: (phoneType: PhoneType) => Validate<string | undefined>;
  onSubmit: (values: FormValues) => void;
  onPrev: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onFieldCompleted?: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onPhoneUpdate: (phone: string | undefined, type: PhoneType) => void;
}>;

export const AdditionalEmploymentDetails: React.FC<Props> = ({
  employmentStatus,
  initialValue,
  validatePhoneUnicity,
  onSubmit,
  onPrev,
  onFieldCompleted,
  onPhoneUpdate,
}) => {
  const methods = useForm<FormValues>({
    mode: 'all',
    defaultValues: initialValue,
  });
  const { getValues, handleSubmit } = methods;
  useFormInitialize(methods, initialValue);

  const showReference = shouldAskForReference(employmentStatus);

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };

  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onPrev(getValues(), actualErrors);
  };

  const isBusinessOwner = employmentStatus === 'OWN_BUSINESS';
  const title = isBusinessOwner
    ? 'About your business'
    : 'About your employment';

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(onSubmit)}>
      <Screen
        header={<HeaderWithLogo />}
        footer={<FormFooter onClickPrev={handleClickButtonPrev} />}
      >
        <div className="mb-5">
          <ScreenTitle
            title={title}
            icon={<img src={employmentImage} width={56} height={56} alt="" />}
          />
        </div>
        <FormProvider {...methods}>
          <CompanyDetails employmentStatus={employmentStatus} />
          {showReference && (
            <CompanyReference
              employmentStatus={employmentStatus}
              onPhoneUpdate={onPhoneUpdate}
              validatePhoneUnicity={validatePhoneUnicity}
            />
          )}
        </FormProvider>
      </Screen>
    </form>
  );
};
