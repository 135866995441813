import type {
  PersonFormatter,
  PersonRecovery,
  PersonScreensMapping,
} from '~entities/person';
import {
  formatPersonValuesByMapping,
  recoverPersonValuesByMapping,
} from '~entities/person';

import type { FormValues } from '../ui/additional-document';

const mapping: PersonScreensMapping<Omit<FormValues, 'idPhoto'>> = {
  type: {
    path: ['scoringData', 'additionalDocument', 'type'],
  },
  idNumber: {
    path: ['scoringData', 'additionalDocument', 'idNumber'],
  },
};

export const formatAdditionalDocument: PersonFormatter<FormValues> = (
  person,
  screenValues
) => {
  if (!screenValues.type || !screenValues.idNumber) {
    return person;
  }

  return formatPersonValuesByMapping(person, screenValues, mapping);
};

export const recoverAdditionalDocument: PersonRecovery<FormValues> = (
  person
) => {
  return recoverPersonValuesByMapping(person, mapping);
};
