import type {
  PersonFormatter,
  PersonRecovery,
  PersonScreensMapping,
} from '~entities/person';
import {
  formatPersonValuesByMapping,
  recoverPersonValuesByMapping,
} from '~entities/person';

import type { FormValues } from '../ui/id-document-number';

const mapping: PersonScreensMapping<Omit<FormValues, 'idPhoto'>> = {
  idNumber: {
    path: ['document', 'idNumber'],
  },
  idType: {
    path: ['document', 'type'],
  },
};

export const formatIdDocumentNumber: PersonFormatter<FormValues> = (
  person,
  screenValues
) => {
  if (
    // the user hasn't filled id number or id type yet
    !screenValues.idNumber ||
    !screenValues.idType ||
    // the user has changed document type
    person.document?.type !== screenValues.idType
  ) {
    return { ...person, document: undefined };
  }

  return formatPersonValuesByMapping(person, screenValues, mapping);
};

export const recoverIdDocumentNumber: PersonRecovery<FormValues> = (person) => {
  return recoverPersonValuesByMapping(person, mapping);
};
