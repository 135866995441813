import { useQueuedMutation } from '~shared/hooks';

import type {
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useQuery, useMutation } from '@tanstack/react-query';

import type {
  CreateApplicationPayload,
  SubmitApplicationPayload,
  UpdateApplicationPayload,
} from '../api';
import { gatewayApplicationApi } from '../api';
import type { Application } from '../application';

export const useGetApplicationByIdQuery = (
  id: string,
  options?: Omit<
    UseQueryOptions<any, Error, Application>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery({
    queryKey: ['applications/get', id],
    queryFn: async () => gatewayApplicationApi.getApplicationById(id),
    ...options,
  });
};

export const useUpdateApplicationMutation = (
  options?: UseMutationOptions<void, Error, UpdateApplicationPayload>
) => {
  return useQueuedMutation({
    mutationKey: ['updateApplication'],
    mutationFn: (application: UpdateApplicationPayload) =>
      gatewayApplicationApi.updateApplication(application),
    ...options,
  });
};

export const useCreateOrFindApplicationMutation = () => {
  return useMutation({
    mutationKey: ['createApplication'],
    mutationFn: async (payload: CreateApplicationPayload) =>
      gatewayApplicationApi.findOrCreateApplication(payload),
  });
};

export const useSubmitApplicationMutation = (
  options?: UseMutationOptions<any, Error, SubmitApplicationPayload>
) => {
  return useMutation({
    mutationKey: ['submitApplication'],
    mutationFn: async (payload: SubmitApplicationPayload) =>
      gatewayApplicationApi.submitApplication(payload),
    ...options,
  });
};
