import { useState } from 'react';

import type { ConfirmationErrorType } from '~features/otp';
import { AuthorizationError, OtpConfirmation } from '~features/otp';

import { HeaderWithClose } from '~shared/header-with-close/header-with-close';
import { delayedPromise } from '~shared/lib/delayed/delayed';
import { nonNullableValue } from '~shared/types/non-nullable-value';
import { Screen } from '~shared/ui/screen';
import { ScrollIntoView } from '~shared/ui/scroll-into-view';
import { Step, Stepper } from '~shared/ui/stepper';

import { useSetAtom } from 'jotai';
import { loginAtom } from '~demo/entities/auth';

import { ClientPhone } from './ui/client-phone/client-phone';

const stepsNames = {
  clientPhone: 'clientPhone',
  smsConfirmation: 'smsConfirmation',
};

type Props = {
  initialPhone?: string;
  onLogin?: (phone: string) => void;
  onCancel?: () => void;
};

export const AuthByPhone: React.FC<Props> = ({
  initialPhone,
  onLogin,
  onCancel,
}) => {
  const [phoneNumber, setPhoneNumber] = useState(initialPhone ?? '');
  const [stepName, setStepName] = useState(stepsNames.clientPhone);
  const [confirmationError, setConfirmationError] =
    useState<ConfirmationErrorType>();
  const [isAuthError, setIsAuthError] = useState<boolean>(false);
  const login = useSetAtom(loginAtom);

  const handleLoginSuccess = () => {
    login(phoneNumber);
    onLogin?.(phoneNumber);
  };

  const handleClientInfoSubmit = async (phone: string) => {
    setIsAuthError(false);
    setPhoneNumber(phone);
    setStepName(stepsNames.smsConfirmation);
  };

  if (isAuthError) {
    return <AuthorizationError />;
  }

  const handleCloseAuth =
    stepName === stepsNames.smsConfirmation
      ? () => setStepName(stepsNames.clientPhone)
      : onCancel;
  const headerText =
    stepName === stepsNames.smsConfirmation ? 'Enter the code' : undefined;

  return (
    <Screen
      header={<HeaderWithClose onClose={handleCloseAuth} text={headerText} />}
    >
      <Stepper current={stepName}>
        <Step name={stepsNames.clientPhone}>
          <ScrollIntoView>
            <ClientPhone
              onSubmit={handleClientInfoSubmit}
              initialPhone={initialPhone}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepsNames.smsConfirmation}>
          <ScrollIntoView>
            <OtpConfirmation
              phoneNumber={nonNullableValue(phoneNumber)}
              confirmationError={confirmationError}
              onCodeSubmit={async (code) => {
                setIsAuthError(false);
                await delayedPromise(200);
                if (code !== '123456') {
                  setConfirmationError('confirmationError');
                } else {
                  handleLoginSuccess();
                }
              }}
              onCodeResend={async () => {
                await delayedPromise(200);
              }}
              onCodeResendLimitError={() => {
                setConfirmationError(undefined);
                setIsAuthError(true);
              }}
            />
          </ScrollIntoView>
        </Step>
      </Stepper>
    </Screen>
  );
};
