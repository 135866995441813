import { useParams } from 'react-router-dom';

import {
  ApplicationStatusUI,
  getReviewTime,
  isWaitingForDecision,
  reviewTimeExpired,
  useGetApplicationByIdQuery,
} from '~entities/application';

import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Loader } from '~shared/ui/loader';
import { Screen } from '~shared/ui/screen';

export const ApplicationStatus: React.FC = () => {
  const params = useParams();
  const { applicationId } = params;

  if (!applicationId) {
    throw new Error('Application ID is required');
  }

  const { data: application } = useGetApplicationByIdQuery(applicationId, {
    refetchInterval: (q) => {
      const result = q.state.data;
      const reviewTime =
        (result &&
          getReviewTime(new Date(result?.applicationStatusChangedUTC))) ??
        0;
      const shouldPollApplication =
        result &&
        isWaitingForDecision(result) &&
        !reviewTimeExpired(reviewTime);

      return shouldPollApplication ? 1000 : false;
    },
  });

  return (
    <Screen header={<HeaderWithLogo />}>
      {application ? (
        <ApplicationStatusUI {...application} />
      ) : (
        <Loader centered />
      )}
    </Screen>
  );
};
