import { environment } from '~app/environment';

import {
  webRequestClient,
  type RequestParams,
  type ScpClient,
} from '~shared/scp-client';

import type { PersonApi, UpdatePersonPayload } from './person-api';

import type { Person } from '../person';

type Options = { host: string };

export class GatewayPersonApi implements PersonApi {
  scpClient: ScpClient;
  host: string;

  constructor(scpClient: ScpClient, options: Options) {
    this.scpClient = scpClient;
    this.host = options.host;
  }

  getPerson(applicationId: string) {
    return this.makeRequest<Person>({
      method: 'get',
      payload: { applicationId },
    });
  }

  updatePerson(payload: UpdatePersonPayload): Promise<any> {
    return this.makeRequest<any>({
      method: 'update',
      payload,
    });
  }

  private makeRequest<T>(params: Partial<RequestParams> & { method: string }) {
    return this.scpClient
      .call<T>({
        host: this.host,
        version: 'v1',
        domain: 'origination',
        service: 'web/pos-online/person',
        withCredentials: true,
        ...params,
      })
      .then((response) => response.payload);
  }
}

export const gatewayPersonApi = new GatewayPersonApi(webRequestClient, {
  host: environment.API_HOST,
});
