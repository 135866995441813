import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const userPhoneAtom = atomWithStorage<string | null>(
  'user_phone_demo',
  null,
  undefined,
  { getOnInit: true }
);
export const isAuthorisedAtom = atom<boolean>(false);

export const loginAtom = atom(null, (_get, set, phone: string) => {
  set(userPhoneAtom, phone);
  set(isAuthorisedAtom, true);
});
