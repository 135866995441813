import type { Validate } from 'react-hook-form';

import isEmpty from '@tinkoff/utils/is/empty';
import isString from '@tinkoff/utils/is/string';
import trim from '@tinkoff/utils/string/trim';

const pattern =
  // eslint-disable-next-line no-control-regex,no-useless-escape
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)(?:([\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|\\([\x01-\x09\x0b\x0c\x0e-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\x20|\x09|\x0d|\x0a))*(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]{2,}|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,})|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

// По большому счету, символы филиппинского алфавита
// тоже могут быть в email, но мы их пока не проверяем,
// поэтому просто возьмем символы из регулярки выше.
const allowedSymbolsPattern = /^[a-z\d!#$%&'*+-/=?^_`{|}~.@]+$/i;

type EmailValidatorOptions = {
  invalidFormatText: string;
  invalidSymbolsText: string;
  text?: string;
};

export function email({
  invalidFormatText,
  invalidSymbolsText,
  text = 'Invalid characters',
}: EmailValidatorOptions): Validate<unknown> {
  return function emailValidator(value) {
    if (isEmpty(value) || !isString(value)) {
      return undefined;
    }

    const trimmedValue = trim(value);

    if (!allowedSymbolsPattern.test(trimmedValue)) {
      return invalidSymbolsText || text;
    }

    if (!pattern.test(trimmedValue)) {
      return invalidFormatText || text;
    }

    return undefined;
  };
}
