import { environment } from '~app/environment';

import {
  webRequestClient,
  type RequestParams,
  type ScpClient,
} from '~shared/scp-client';

import type { OrderApi } from './orders-api';

import type { Order } from '../order';

type Options = { host: string };

export class GatewayOrdersApi implements OrderApi {
  scpClient: ScpClient;
  host: string;

  constructor(scpClient: ScpClient, options: Options) {
    this.scpClient = scpClient;
    this.host = options.host;
  }

  getOrder(orderId: string): Promise<Order> {
    return this.makeRequest<Order>({
      method: 'get',
      payload: { orderId },
    });
  }

  private makeRequest<T>(params: Partial<RequestParams> & { method: string }) {
    return this.scpClient
      .call<T>({
        host: this.host,
        version: 'v1',
        domain: 'origination',
        service: 'web/pos-online/orders',
        ...params,
      })
      .then((response) => response.payload);
  }
}

export const gatewayOrdersApi = new GatewayOrdersApi(webRequestClient, {
  host: environment.API_HOST,
});
