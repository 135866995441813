import type { HttpError } from '~shared/errors';
import { isHttpError } from '~shared/errors';

export enum FindOrCreateApplicationErrorCode {
  orderExpired = 'CAN_NOT_CREATE_DUE_TO_ORDER_EXPIRED',
  activeLoan = 'CAN_NOT_CREATE_DUE_TO_ACTIVE_LOAN',
  activeApplication = 'CAN_NOT_CREATE_DUE_TO_ACTIVE_APPLICATION',
  rejectionCooldown = 'CAN_NOT_CREATE_DUE_TO_REJECTION_COOLDOWN',
  submittedApplication = 'CAN_NOT_CREATE_DUE_TO_APPLICATION_ALREADY_SUBMITTED',
}

export type FindOrCreateApplicationError = HttpError & {
  code: FindOrCreateApplicationErrorCode;
};

export function isFindOrCreateApplicationError(
  error: Error
): error is FindOrCreateApplicationError {
  return isHttpError(error) && error.status === 400 && !!error.code;
}
