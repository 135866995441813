import type { FieldErrors } from 'react-hook-form';
import { Controller, useForm, FormProvider } from 'react-hook-form';

import { useFormInitialize } from '~shared/hooks';
import { combineValidators } from '~shared/lib/combine-validators';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';
import { validators } from '~shared/validators';

import { Checkbox, Input } from '@breeze/rhf-adapters';
import { AddressSuggestion } from '~demo/entities/address/ui';

import addressImage from '../../images/address.webp';
import { STREET_HOUSE_FIELD_NAME, UNIT_FLOOR_FIELD_NAME } from '../constants';
import type { FormValues } from '../types';

const fieldNames = {
  country: 'country',
  region: 'region',
  postalCode: 'postalCode',
  province: 'province',
  cityOrMunicipality: 'cityOrMunicipality',
  barangay: 'barangay',
};

type Props = {
  initialValue?: Partial<FormValues>;
  onPrev: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onSubmit: (values: FormValues) => void;
  onFieldCompleted?: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
};

export const ResidentialAddress: React.FC<Props> = (props) => {
  const { initialValue = {}, onSubmit, onFieldCompleted, onPrev } = props;

  const methods = useForm<FormValues>({
    mode: 'all',
    defaultValues: initialValue,
  });
  const { getValues, handleSubmit, control } = methods;

  useFormInitialize(methods, initialValue);

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };

  const handlePrevClick = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onPrev(getValues(), actualErrors);
  };

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(onSubmit)}>
      <Screen
        header={<HeaderWithLogo />}
        footer={<FormFooter onClickPrev={handlePrevClick} />}
      >
        <div className="mb-5">
          <ScreenTitle
            title="Where do you live right now?"
            subtitle="Fill in your residential address here"
            icon={<img src={addressImage} width={56} height={56} alt="" />}
          />
        </div>
        <FormProvider {...methods}>
          <AddressSuggestion fieldNames={fieldNames} />
          <Controller
            name="streetAndHouse"
            control={control}
            rules={{
              required: 'Enter your house number and street name',
              validate: combineValidators(
                validators.maxLength({ maxLength: 150 }),
                validators.filipinoAddress()
              ),
            }}
            render={(fieldProps) => (
              <Input label={STREET_HOUSE_FIELD_NAME} {...fieldProps} />
            )}
          />
          <Controller
            name="unitAndFloor"
            control={control}
            rules={{
              validate: combineValidators(
                validators.maxLength({ maxLength: 150 }),
                validators.filipinoAddress()
              ),
            }}
            render={(fieldProps) => (
              <Input label={UNIT_FLOOR_FIELD_NAME} {...fieldProps} />
            )}
          />
          <Controller
            name="hasDifferentPermanentAddress"
            control={control}
            render={(fieldProps) => (
              <Checkbox {...fieldProps}>
                My&nbsp;permanent address differs from my&nbsp;residential
                address
              </Checkbox>
            )}
          />
        </FormProvider>
      </Screen>
    </form>
  );
};
