import type { Order } from '~entities/order';

import phoneImage from './phone.png';

export const orderMock: Order = {
  id: 'demo_order',
  paymentAmount: 12998,
  orderItems: [
    {
      name: 'Xiaomi Redmi 10 64 GB',
      price: 6999,
      quantity: 2,
      imageUrl: phoneImage,
    },
  ],
  product: {
    minLoanAmount: 3000,
    maxLoanAmount: 25000,
    minDownPaymentPercent: 20,
    maxDownPaymentPercent: 90,
    termsInMonths: [6, 9, 12],
    interestRate: 0,
    originationFeeFlat: 0,
    originationFeeRate: 0,
  },
};
