import { useEffect } from 'react';
import type { PropsWithChildren } from 'react';

import { Divider } from '@breeze-platform-ui/layout';

import styles from './screen.module.css';

type Props = {
  header?: React.ReactNode;
  footer?: React.ReactNode;
} & PropsWithChildren;

/**
 * Wrapper for page layout
 * @param header - Sticky header, will be displayed at the top of the page, above the scrolled content
 * @param footer - Fixed footer, will be displayed at the bottom of the page
 */
export const Screen: React.FC<Props> = ({ header, footer, children }) => {
  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    setVh();
    window.addEventListener('resize', setVh);
    return () => {
      window.removeEventListener('resize', setVh);
    };
  }, []);
  return (
    <div className={`${styles.wrapper} m-auto flex max-w-[768px] flex-col`}>
      {header && (
        <div className="sticky top-0 z-[1] box-border w-full bg-white">
          <div className="box-border flex w-full items-center p-4">
            {header}
          </div>
          <Divider color="rgba(0, 0, 0, 0.07)" />
        </div>
      )}
      <div className="box-border max-h-full grow overflow-y-auto px-4 py-8">
        {children}
      </div>
      {footer && (
        <div className="box-border w-full bg-white">
          <Divider color="rgba(0, 0, 0, 0.07)" />
          <div className="box-border flex w-full items-center p-4">
            {footer}
          </div>
        </div>
      )}
    </div>
  );
};
