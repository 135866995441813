import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import {
  OTP_REQUEST_COUNT_LIMIT,
  OTP_REQUEST_DELAY_LONG,
} from '../lib/get-otp-request-delay';

export type RequestState = { lastRequestTime: number; attemptsCount: number };

const otpRequestsAtom = atomWithStorage<Record<string, RequestState> | null>(
  'product_loan_otp_requests',
  null,
  undefined,
  { getOnInit: true }
);

export const lastOtpRequestPhoneAtom = atom<string | null>(null);

export const lastOtpRequestAtom = atom(
  (get) => {
    const phone = get(lastOtpRequestPhoneAtom);
    return (phone && get(otpRequestsAtom)?.[phone]) || undefined;
  },
  (get, set, args: { phone: string; time: number }) => {
    const { phone, time } = args;
    set(lastOtpRequestPhoneAtom, phone);

    const prevRequest = get(otpRequestsAtom)?.[phone];
    if (!prevRequest) {
      set(otpRequestsAtom, {
        ...get(otpRequestsAtom),
        [phone]: {
          lastRequestTime: time,
          attemptsCount: 1,
        },
      });

      return;
    }

    const lastCount =
      prevRequest &&
      new Date().getTime() - prevRequest.lastRequestTime <
        OTP_REQUEST_DELAY_LONG
        ? prevRequest.attemptsCount
        : 0;

    set(otpRequestsAtom, {
      ...get(otpRequestsAtom),
      [phone]: {
        lastRequestTime: time,
        attemptsCount: (lastCount % OTP_REQUEST_COUNT_LIMIT) + 1,
      },
    });
  }
);

export const resetLastOtpRequestsAtom = atom(
  null,
  (get, set, phone: string) => {
    const lastRequest = get(otpRequestsAtom);
    if (lastRequest?.[phone]) {
      delete lastRequest[phone];
      set(lastOtpRequestPhoneAtom, null);
      set(otpRequestsAtom, lastRequest);
    }
  }
);

export const clearOldRequests = atom(null, (get, set) => {
  const lastRequest = get(otpRequestsAtom) ?? {};
  Object.keys(lastRequest).forEach((key) => {
    if (
      lastRequest[key].lastRequestTime + OTP_REQUEST_DELAY_LONG <
      new Date().getTime()
    ) {
      delete lastRequest[key];

      if (key === get(lastOtpRequestPhoneAtom)) {
        set(lastOtpRequestPhoneAtom, null);
      }
    }
  });
  set(otpRequestsAtom, lastRequest);
});

export const isAttemptsLimitReachedAtom = atom((get) => {
  const attemptsCount = get(lastOtpRequestAtom)?.attemptsCount;
  return attemptsCount && attemptsCount >= OTP_REQUEST_COUNT_LIMIT;
});
