import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  isAttemptsLimitReachedAtom,
  lastOtpRequestAtom,
} from '~features/otp/model';

import { ButtonLink } from '@breeze-platform-ui/button';
import { Box, Col } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';
import Tooltip from '@breeze-platform-ui/tooltip';
import { useAtom, useAtomValue } from 'jotai';

import { ProblemsTooltip } from './problems-tooltip/problems-tooltip';
import { ResendButton } from './resend-button';

import {
  getAttemptsLimitMessage,
  getMaskedPhone,
  getOtpRequestDelaySeconds,
  useResendCountdown,
} from '../../lib';
import { ConfirmationInput } from '../confirmation-input/confirmation-input';

type FormValues = {
  confirmationCode: string;
};

export type ConfirmationErrorType =
  | 'confirmationError'
  | 'attemptsLimitError'
  | 'unexpectedError';

type Props = {
  phoneNumber: string;
  confirmationError?: ConfirmationErrorType;
  isConfirmationInProgress?: boolean;
  onCodeResend: () => void;
  onCodeResendLimitError: () => void;
  onCodeSubmit: (code: string) => void;
};

export const OtpConfirmation = ({
  phoneNumber,
  confirmationError,
  isConfirmationInProgress,
  onCodeResend,
  onCodeResendLimitError,
  onCodeSubmit,
}: Props): JSX.Element => {
  const maskedPhone = useMemo(() => getMaskedPhone(phoneNumber), [phoneNumber]);

  const { control, handleSubmit } = useForm<FormValues>({
    mode: 'all',
    shouldUnregister: true,
  });
  const [lastOtpRequest, setLastOtpRequest] = useAtom(lastOtpRequestAtom);
  const isAttemptsLimitReached = useAtomValue(isAttemptsLimitReachedAtom);
  const [timer, startCountdown] = useResendCountdown();

  useEffect(() => {
    const delay = getOtpRequestDelaySeconds(lastOtpRequest);

    if (delay) {
      startCountdown(delay);
    }
  }, [lastOtpRequest, startCountdown]);

  const confirmationErrorContent = useMemo(() => {
    switch (confirmationError) {
      case 'confirmationError':
        return 'Wrong code';
      case 'attemptsLimitError':
        return (
          <span>
            You have exceeded the&nbsp;maximum number of&nbsp;attempts. Please,
            request a&nbsp;new code
          </span>
        );
      case 'unexpectedError':
        return <span>Error checking the&nbsp;code</span>;
      default:
        return undefined;
    }
  }, [confirmationError]);

  const handleCodeResend = async () => {
    await onCodeResend();
    setLastOtpRequest({ phone: phoneNumber, time: new Date().getTime() });
  };

  const handleOnCodeResendLimitError = async () => {
    await onCodeResendLimitError();
    setLastOtpRequest({ phone: phoneNumber, time: new Date().getTime() });
  };

  const showResendButton = !isAttemptsLimitReached || !timer;
  const showAttemptsLimitError = isAttemptsLimitReached && !!timer;

  return (
    <Col gaps={16} alignCross="stretch">
      <Text color="rgba(0, 0, 0, 0.8)" size={15}>
        We sent a&nbsp;confirmation code to{' '}
        <Text display="inline" whiteSpace="nowrap">
          {maskedPhone}
        </Text>
      </Text>
      <Box width={228}>
        <form
          onSubmit={handleSubmit(({ confirmationCode }) =>
            onCodeSubmit(confirmationCode)
          )}
        >
          <Controller
            name="confirmationCode"
            control={control}
            rules={{
              required: 'Please, enter the code',
              pattern: {
                value: /^\d{6}$/,
                message: 'The code should have 6 digits',
              },
            }}
            render={(props) => (
              <ConfirmationInput
                {...props}
                label="SMS code"
                autoComplete="one-time-code"
                disabled={isConfirmationInProgress}
                confirmationError={confirmationErrorContent}
                onFieldDone={(_event, { value }) => onCodeSubmit(value)}
              />
            )}
          />
        </form>
      </Box>
      <Col gaps={16} alignCross="start">
        {showAttemptsLimitError && (
          <Text color="rgb(227, 28, 28)">{getAttemptsLimitMessage(timer)}</Text>
        )}
        {showResendButton && (
          <ResendButton
            timer={timer}
            disabled={isConfirmationInProgress}
            onResend={handleCodeResend}
            onResendLimitError={handleOnCodeResendLimitError}
          />
        )}
        <Tooltip
          popoverContent={<ProblemsTooltip />}
          theme="dark"
          align="start"
          hideOnClickOutside
          hideOnTouchMove
          toggleOnClick
        >
          <ButtonLink
            size="s"
            disableHorizontalPadding
            disableVerticalPadding
            disabled={isConfirmationInProgress}
          >
            I haven’t received the&nbsp;code
          </ButtonLink>
        </Tooltip>
      </Col>
    </Col>
  );
};
