import React, { useCallback, useEffect } from 'react';
import type { Validate } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';

import type { PhoneType } from '~entities/person';

import { combineValidators } from '~shared/lib/combine-validators';
import { SectionTitle } from '~shared/ui/section-title';
import { validators } from '~shared/validators';

import { InputCapitalize, InputPhone, Select } from '@breeze/rhf-adapters';

import type { FormValues } from './additional-employment-details';

import {
  REFERENCE_NAME_REQUIRED_ERROR,
  REFERENCE_PHONE_REQUIRED_ERROR,
  SELECT_REQUIRED_ERROR,
} from '../../constants/errors';
import {
  REFERENCE_FIELD_NAME,
  REFERENCE_PHONE_FIELD_NAME,
} from '../../constants/field-names';
import type { EmploymentStatusValue } from '../../lib';
import { isEmployeeFn } from '../../lib';
import { getReferenceTypeOptions } from '../../lib';

type Props = Readonly<{
  employmentStatus: EmploymentStatusValue;
  validatePhoneUnicity: (phoneType: PhoneType) => Validate<string | undefined>;
  onPhoneUpdate: (phone: string | undefined, type: PhoneType) => void;
}>;

export const CompanyReference: React.FC<Props> = ({
  employmentStatus,
  validatePhoneUnicity,
  onPhoneUpdate,
}) => {
  const { control, getFieldState, getValues, trigger, watch } =
    useFormContext<FormValues>();

  // TODO: create wrapper for phone inputs, which incapsulates phones unicity validation logic
  const triggerPhone = useCallback(async () => {
    const value = getValues('workPhone');

    if (getFieldState('workPhone').isDirty || value) {
      const isValid = await trigger('workPhone');

      onPhoneUpdate(isValid ? value : undefined, 'work');
    }
  }, [getFieldState, getValues, trigger, onPhoneUpdate]);

  useEffect(() => {
    triggerPhone();
  }, [triggerPhone, validatePhoneUnicity]);

  const isNotEmployee = !isEmployeeFn(employmentStatus);
  const referenceTypeOptions = getReferenceTypeOptions(employmentStatus);
  const referenceType = watch('reference');

  const isReferenceNameShown =
    isNotEmployee ||
    // if hide reference name by default, it is initially rerendered
    // and unregistered => initial value is resetted to undefined
    !referenceType ||
    ['MANAGER', 'COWORKER'].includes(referenceType);

  return (
    <>
      <div className="mb-5">
        <SectionTitle
          title="Reference contact"
          subtitle="Must be someone connected with&nbsp;your place of&nbsp;work. Make sure to&nbsp;inform this contact that they might get a&nbsp;call from us"
        />
      </div>
      <Controller
        name="reference"
        control={control}
        shouldUnregister
        rules={{
          required: SELECT_REQUIRED_ERROR,
        }}
        render={(fieldProps) => (
          <Select
            {...fieldProps}
            label={REFERENCE_FIELD_NAME}
            options={referenceTypeOptions}
          />
        )}
      />
      <Controller
        name="workPhone"
        control={control}
        shouldUnregister
        rules={{
          required: REFERENCE_PHONE_REQUIRED_ERROR,
          validate: combineValidators(
            validators.phoneLength(),
            validators.phoneFormat(),
            validatePhoneUnicity('work')
          ),
        }}
        render={(fieldProps) => (
          <InputPhone
            {...fieldProps}
            field={{
              ...fieldProps.field,
              onBlur: () => {
                const { error } = fieldProps.fieldState;
                const { value } = fieldProps.field;

                onPhoneUpdate(!error ? value : undefined, 'work');
                fieldProps.field.onBlur();
              },
            }}
            label={REFERENCE_PHONE_FIELD_NAME}
          />
        )}
      />
      {isReferenceNameShown && (
        <Controller
          name="referenceName"
          control={control}
          shouldUnregister
          rules={{
            validate: combineValidators(
              validators.required({
                text: REFERENCE_NAME_REQUIRED_ERROR,
              }),
              validators.maxLength({ maxLength: 300 }),
              validators.filipinoName()
            ),
          }}
          render={(fieldProps) => (
            <InputCapitalize
              {...fieldProps}
              label="Name of&nbsp;the&nbsp;reference"
            />
          )}
        />
      )}
    </>
  );
};
