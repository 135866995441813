import type { Validate } from 'react-hook-form';

import isEmpty from '@tinkoff/utils/is/empty';
import isString from '@tinkoff/utils/is/string';
import trim from '@tinkoff/utils/string/trim';

const pattern = /^[\s.’'"”\-a-zA-ZÑñ0-9]+$/;

interface Options {
  text: string;
}

export function filipinoCompany(options?: Options): Validate<unknown> {
  return function filipinoCompanyValidator(value) {
    const text =
      options?.text ?? 'Use only letters, numbers, spaces, or symbols . - ’ ”';

    if (isString(value) && !isEmpty(value) && !pattern.test(trim(value))) {
      return text;
    }

    return undefined;
  };
}
