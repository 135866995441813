import { useEffect, useState } from 'react';

import type { Order } from '~entities/order';
import { orderMock } from '~entities/order/api/order-mock';

import { nonNullableValue } from '~shared/types/non-nullable-value';
import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Loader } from '~shared/ui/loader';
import { Screen } from '~shared/ui/screen';

import { ApplicationDetails } from './application-details/application-details';
import { LoanApplicationForm } from './form/loan-application-form';

export const DemoPage: React.FC = () => {
  const [order, setOrder] = useState<Order>();
  const [isApplicationLoading, setIsApplicationLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const [_applicationId, setApplicationId] = useState<string>();
  const [isFormLoading, setIsFormLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOrder(orderMock);
      setIsApplicationLoading(false);
    }, 300);
  }, []);

  const handleLogin = () => {
    setIsFormLoading(true);
    setTimeout(() => {
      setApplicationId('demo_id');
      setIsFormLoading(false);
    });
  };

  const handleSubmit = () => {
    setIsApplicationLoading(true);
    setTimeout(() => {
      setSubmitted(true);
      setIsApplicationLoading(false);
    }, 300);
  };

  if (isApplicationLoading) {
    return (
      <Screen header={<HeaderWithLogo />}>
        <Loader centered />
      </Screen>
    );
  }

  if (submitted) {
    return <ApplicationDetails />;
  }

  return (
    <LoanApplicationForm
      order={nonNullableValue(order)}
      onLogin={handleLogin}
      isLoading={isFormLoading}
      onSubmit={handleSubmit}
    />
  );
};
