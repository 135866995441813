import type { Validate } from 'react-hook-form';

import isEmpty from '@tinkoff/utils/is/empty';
import isNumber from '@tinkoff/utils/is/number';
import isObject from '@tinkoff/utils/is/object';
import isString from '@tinkoff/utils/is/string';
import trim from '@tinkoff/utils/string/trim';

import { plural } from '../../lib/plural';

interface MixLenOptions {
  minLength: number;
  text?: string;
}

export function minLength(options: MixLenOptions): Validate<unknown> {
  return function minLengthValidator(value) {
    const isOptionsValid = isObject(options) && isNumber(options.minLength);
    if (!isOptionsValid) {
      return undefined;
    }

    if (!isString(value) || isEmpty(value)) {
      return undefined;
    }

    if (trim(value).length < options.minLength) {
      return (
        options.text ??
        `Minimum ${options.minLength} ${plural(
          options.minLength,
          'character',
          'characters'
        )}`
      );
    }
  };
}
