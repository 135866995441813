import type { EmploymentStatusValue } from '~widgets/application-form/employment/lib';

import { shouldAskForAdditionalId } from '~entities/person';

import { createMachine } from 'xstate';

import type { StepName } from '../lib';
import { stepNames } from '../lib';

export const StepFlowMachine: (
  initialStep: StepName
) => ReturnType<typeof createMachine> = (
  initialStep: StepName
): ReturnType<typeof createMachine> => {
  return createMachine({
    id: 'pos-online-application-form',
    predictableActionArguments: true,
    initial: initialStep,
    states: {
      [stepNames.loanDetails]: {
        on: {
          next: {
            target: stepNames.personalDetails,
          },
        },
      },
      [stepNames.personalDetails]: {
        on: {
          next: {
            target: stepNames.additionalPersonalDetails,
          },
        },
      },
      [stepNames.additionalPersonalDetails]: {
        on: {
          next: {
            target: stepNames.residentialAddress,
          },
        },
      },
      [stepNames.residentialAddress]: {
        on: {
          next: [
            {
              target: stepNames.permanentAddress,
              cond: (formValues: any) =>
                formValues.residentialAddress?.hasDifferentPermanentAddress,
            },
            {
              target: stepNames.employmentDetails,
              cond: (formValues: any) =>
                !formValues.residentialAddress?.hasDifferentPermanentAddress,
            },
          ],
        },
      },
      [stepNames.permanentAddress]: {
        on: {
          next: {
            target: stepNames.employmentDetails,
          },
        },
      },
      [stepNames.employmentDetails]: {
        on: {
          next: [
            /**
             * TODO: rewrite it later, added just to make
             * transitions work
             */
            {
              target: stepNames.additionalEmploymentDetails,
              cond: (formValues: any) =>
                !['OFW', 'UNEMPLOYED'].includes(
                  formValues.employmentDetails
                    ?.employmentStatus as EmploymentStatusValue
                ),
            },
            {
              target: stepNames.finances,
              cond: (formValues: any) =>
                ['OFW', 'UNEMPLOYED'].includes(
                  formValues.employmentDetails
                    ?.employmentStatus as EmploymentStatusValue
                ),
            },
          ],
        },
      },
      [stepNames.additionalEmploymentDetails]: {
        on: {
          next: {
            target: stepNames.finances,
          },
        },
      },
      [stepNames.finances]: {
        on: {
          next: {
            target: stepNames.alternativeContacts,
          },
        },
      },
      [stepNames.alternativeContacts]: {
        on: {
          next: {
            target: stepNames.idDocumentType,
          },
        },
      },
      [stepNames.idDocumentType]: {
        on: {
          next: [
            {
              target: stepNames.mainIdDocumentNumber,
              cond: (formValues: any) =>
                !shouldAskForAdditionalId(formValues.idDocumentType?.typeMain),
            },
            {
              target: stepNames.otherIdDocumentNumber,
              cond: (formValues: any) =>
                shouldAskForAdditionalId(formValues.idDocumentType?.typeMain),
            },
          ],
        },
      },
      [stepNames.mainIdDocumentNumber]: {
        type: 'final',
      },
      [stepNames.otherIdDocumentNumber]: {
        on: {
          next: {
            target: stepNames.additionalDocument,
          },
        },
      },
      [stepNames.additionalDocument]: {
        type: 'final',
      },
    },
  });
};
