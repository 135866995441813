import errorImg from '~shared/images/error.webp';
import { Button } from '~shared/ui/button';
import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Result } from '~shared/ui/result';
import { Screen } from '~shared/ui/screen';

export const NotFound = () => (
  <Screen header={<HeaderWithLogo />}>
    <Result
      image={errorImg}
      title="Whoops! 404 Page Not Found"
      subtitle="Looks like this page doesn't exist&nbsp;―&nbsp;please check the&nbsp;URL and try again"
      // navigate user to POS online landing page, when is exists
      footer={
        <Button href="https://www.skyro.ph/" target="_blank">
          Go to Skyro
        </Button>
      }
    />
  </Screen>
);
