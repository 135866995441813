import isObject from '@tinkoff/utils/is/object';
import isString from '@tinkoff/utils/is/string';

export interface Suggestion {
  value: string;
  title: string;
}

export function isSuggestion(suggestion: unknown): suggestion is Suggestion {
  return isObject(suggestion) && isString(suggestion.value);
}
