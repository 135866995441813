import { recoverAdditionalDocument } from '~widgets/application-form/additional-document';
import { recoverAdditionalPersonalDetails } from '~widgets/application-form/additional-personal-details';
import { recoverAlternativeContactNumber } from '~widgets/application-form/alternative-contacts';
import {
  recoverAdditionalEmploymentDetails,
  recoverEmploymentDetails,
} from '~widgets/application-form/employment';
import { recoverFinances } from '~widgets/application-form/finances';
import {
  recoverIdDocumentNumber,
  recoverIdDocumentType,
} from '~widgets/application-form/id-document';
import { recoverLoanCalc } from '~widgets/application-form/loan-calc';
import { recoverPermanentAddress } from '~widgets/application-form/permanent-address';
import { recoverPersonalDetails } from '~widgets/application-form/personal-details';
import { recoverResidentialAddress } from '~widgets/application-form/residential-address';

import type { Application, ApplicationRecovery } from '~entities/application';
import type { Person, PersonRecovery } from '~entities/person';

import type {
  PersonStepName,
  ApplicationStepName,
  StepName,
} from './step-names';
import { personStepNames, applicationStepNames } from './step-names';

type ApplicationRecoveries = {
  [step in ApplicationStepName]: ApplicationRecovery<any>;
};

type PersonDetailsRecoveries = {
  [step in PersonStepName]: PersonRecovery<any>;
};

type ScreensValues = Record<StepName, any>;

const applicationRecoveries: ApplicationRecoveries = {
  [applicationStepNames.loanDetails]: recoverLoanCalc,
};

const personRecoveries: PersonDetailsRecoveries = {
  [personStepNames.personalDetails]: recoverPersonalDetails,
  [personStepNames.additionalPersonalDetails]: recoverAdditionalPersonalDetails,
  [personStepNames.residentialAddress]: recoverResidentialAddress,
  [personStepNames.permanentAddress]: recoverPermanentAddress,
  [personStepNames.employmentDetails]: recoverEmploymentDetails,
  [personStepNames.finances]: recoverFinances,
  [personStepNames.additionalEmploymentDetails]:
    recoverAdditionalEmploymentDetails,
  [personStepNames.alternativeContacts]: recoverAlternativeContactNumber,
  [personStepNames.idDocumentType]: recoverIdDocumentType,
  [personStepNames.otherIdDocumentNumber]: recoverIdDocumentNumber,
  [personStepNames.mainIdDocumentNumber]: recoverIdDocumentNumber,
  [personStepNames.additionalDocument]: recoverAdditionalDocument,
};

export function recoverApplicationScreenValues(application: Application) {
  return (Object.keys(applicationRecoveries) as ApplicationStepName[]).reduce(
    (acc, stepName) => {
      const screenRecovery = applicationRecoveries[stepName];
      acc[stepName] = screenRecovery(application);
      return acc;
    },
    {} as Partial<ScreensValues>
  );
}

export function recoverPersonScreenValues(person: Person) {
  return (Object.keys(personRecoveries) as PersonStepName[]).reduce(
    (acc, stepName) => {
      const screenRecovery = personRecoveries[stepName];
      acc[stepName] = screenRecovery(person);
      return acc;
    },
    {} as Partial<ScreensValues>
  );
}
