import { differenceInSeconds } from 'date-fns';

export const REVIEW_TIME_LIMIT_IN_SEC = 60;

export function getReviewTime(submissionDate: Date): number {
  const currentDate = new Date();
  return Math.max(differenceInSeconds(currentDate, submissionDate), 1);
}

export function reviewTimeExpired(timeInSeconds: number): boolean {
  return timeInSeconds >= REVIEW_TIME_LIMIT_IN_SEC;
}

export function rejectionTimeoutPassed(lastStatusUpdate: Date): boolean {
  return Date.now() - lastStatusUpdate.getTime() > 1000 * 60 * 20;
}
