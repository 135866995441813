import type {
  AddressSuggestionApi,
  AddressSuggestionParams,
} from './address-suggestions-api';

import type { Address } from '../address';
import { getAddressSuggestionsMock } from '../lib/address-suggestions.mocks';

class MockedAddressSuggestionApi implements AddressSuggestionApi {
  async getAddressSuggestion(
    _params: AddressSuggestionParams
  ): Promise<Address[]> {
    return getAddressSuggestionsMock.response.payload.items;
  }
}

export const mockedAddressSuggestionApi = new MockedAddressSuggestionApi();
