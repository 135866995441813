import { useState } from 'react';

import {
  isChallengeConfirmationError,
  useConfirmMutation,
  useResendConfirmationMutation,
} from '~entities/auth';

import { isAuthError } from '~shared/errors';

import { useSetAtom } from 'jotai';

import { OtpConfirmation } from './otp-confirmation';
import type { ConfirmationErrorType } from './otp-confirmation';

import { resetLastOtpRequestsAtom } from '../model';

interface Props {
  phoneNumber: string;
  onSuccess(): void;
  onAuthError?(): void;
}

export const AuthByOtp: React.FC<Props> = ({
  phoneNumber,
  onSuccess,
  onAuthError,
}) => {
  const [confirmationError, setConfirmationError] =
    useState<ConfirmationErrorType>();

  const resetLastOtpRequests = useSetAtom(resetLastOtpRequestsAtom);

  const confirmMutation = useConfirmMutation({
    onError: (error) => {
      if (isChallengeConfirmationError(error)) {
        setConfirmationError('confirmationError');
        return;
      }
      if (isAuthError(error as Error)) {
        setConfirmationError(undefined);
        onAuthError?.();
        return;
      }
      setConfirmationError('unexpectedError');
    },
    onSuccess: () => {
      resetLastOtpRequests(phoneNumber);
      onSuccess();
    },
  });
  const { mutateAsync: resendConfirmationAsync } =
    useResendConfirmationMutation();

  return (
    <OtpConfirmation
      phoneNumber={phoneNumber}
      confirmationError={confirmationError}
      isConfirmationInProgress={confirmMutation.isPending}
      onCodeSubmit={(answer) => {
        setConfirmationError(undefined);
        confirmMutation.mutate({ answer });
      }}
      onCodeResend={resendConfirmationAsync}
      onCodeResendLimitError={() => {
        setConfirmationError(undefined);
        onAuthError?.();
      }}
    />
  );
};
