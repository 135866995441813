import type { Document } from '~entities/person';
import type { PersonFormatter, PersonRecovery } from '~entities/person';

import type {
  MainIdDocumentTypeValue,
  OtherIdDocumentTypeValue,
} from './id-document-type-options';
import {
  idDocumentTypeMainOptions,
  idDocumentTypeOtherOptions,
} from './id-document-type-options';

import type { FormValues } from '../ui/id-document-type';

export const formatIdDocumentType: PersonFormatter<FormValues> = (
  person,
  screenValues
) => {
  const type = screenValues.typeMain || screenValues.typeOther;

  if (!type) {
    return person;
  }

  return {
    ...person,
    document: { ...person.document, type } as Document,
  };
};

export const recoverIdDocumentType: PersonRecovery<FormValues> = (person) => {
  const type = person.document?.type;

  if (idDocumentTypeMainOptions.find((option) => option.value === type)) {
    return { typeMain: type as MainIdDocumentTypeValue };
  }

  if (idDocumentTypeOtherOptions.find((option) => option.value === type)) {
    return { typeOther: type as OtherIdDocumentTypeValue };
  }

  return {};
};
