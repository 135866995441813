import { environment } from '~app/environment';

import { isAuthError } from '~shared/errors';
import {
  webRequestClient,
  type RequestParams,
  type ScpClient,
} from '~shared/scp-client';

import { ChallengeConfirmationError } from '.';

import type {
  AuthApi,
  AuthSuccessResponse,
  InitiateParams,
  RespondParams,
  ValidateResponse,
} from './auth-api';

interface ConstructorParams {
  client: ScpClient;
  clientId: string;
  host: string;
}

export interface GatewayOtpResponse {
  session: string;
  challengeKind: string;
  challengeParameters: {
    username: string;
    delaySec: string;
    expiresAt: string;
    isNew: boolean;
  };
}

function isGatewayOtpResponse(
  response: GatewayOtpResponse | AuthSuccessResponse
): response is GatewayOtpResponse {
  return typeof (response as GatewayOtpResponse)?.session === 'string';
}

export class GatewayAuthApi implements AuthApi {
  private client: ScpClient;
  private clientId: string;
  private host: string;

  private session: string | null = null;
  private username: string | null = null;

  constructor(params: ConstructorParams) {
    this.clientId = params.clientId;
    this.client = params.client;
    this.host = params.host;
  }

  async initiate(params: InitiateParams) {
    this.session = null;
    this.username = null;

    const response = await this.makeRequest<GatewayOtpResponse>({
      method: 'challenge_flow/initiate',
      payload: {
        clientId: this.clientId,
        authParameters: { phoneNumber: params.phoneNumber },
      },
    }).then((resp) => resp.payload);

    this.session = response.session;
    this.username = response.challengeParameters.username;

    return response;
  }

  async respond(params: RespondParams): Promise<AuthSuccessResponse> {
    this.ensureSession();

    const response = await this.makeRequest<
      GatewayOtpResponse | AuthSuccessResponse
    >({
      method: 'challenge_flow/respond',
      payload: {
        clientId: this.clientId,
        session: this.session,
        challengeResponses: {
          answer: params.answer,
          username: this.username,
        },
      },
    }).then((resp) => resp.payload);

    if (isGatewayOtpResponse(response)) {
      this.session = response.session;
      this.username = response.challengeParameters.username;
      throw new ChallengeConfirmationError('Wrong code');
    }

    this.session = null;
    this.username = null;

    return response;
  }

  async resendConfirmation() {
    this.ensureSession();

    const response = await this.makeRequest<GatewayOtpResponse>({
      method: 'challenge_flow/respond',
      payload: {
        clientId: this.clientId,
        session: this.session,
        challengeResponses: {
          repeatChallenge: true,
          username: this.username,
        },
      },
    }).then((resp) => resp.payload);

    this.session = response.session;
    this.username = response.challengeParameters.username;

    return response;
  }

  async validate(): Promise<ValidateResponse> {
    return this.makeRequest<ValidateResponse[]>({
      method: 'tokens/validate',
    }).then((response) => response.payload?.[0]);
  }

  async revoke(): Promise<AuthSuccessResponse> {
    const response = await this.makeRequest<GatewayOtpResponse>({
      method: 'tokens/revoke',
      payload: {
        clientId: this.clientId,
      },
    });
    this.session = null;
    this.username = null;
    return response;
  }

  private ensureSession() {
    if (this.session === null || this.username === null) {
      // eslint-disable-next-line no-console
      console.error('Authorization process has not been initialized');
    }
  }

  private makeRequest<T = unknown>(
    params: Partial<RequestParams> & { method: string }
  ) {
    return this.client
      .call<T>({
        host: this.host,
        version: 'v1',
        domain: 'auth',
        service: 'authorizer',
        withCredentials: true,
        ...params,
      })
      .catch((error) => {
        if (isAuthError(error)) {
          this.session = null;
          this.username = null;
        }

        throw error;
      });
  }
}

export const gatewayAuthApi = new GatewayAuthApi({
  client: webRequestClient,
  clientId: environment.AUTH_CLIENT_ID,
  host: environment.API_HOST,
});
