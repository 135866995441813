/**
 * Returns total loan amount
 * @param purchasePrice - total price of all added products and services
 * @param downPayment - selected downPayment
 * @returns
 */
export function calculateLoanAmount(
  purchasePrice: number,
  downPayment: number
): number {
  return purchasePrice - downPayment;
}
/**
 * Рассчитывает сумму коммиссии за кредит
 *
 * @param requestedAmount сумма кредита, запрошенная заемщиком
 * @param disbursementFee комиссия, %
 * @param disbursementFeeConstant комиссия, стандартный сбор
 * @returns
 */
function calculateFee(
  requestedAmount: number,
  disbursementFee: number,
  disbursementFeeConstant: number
): number {
  return disbursementFeeConstant + requestedAmount * (disbursementFee / 100);
}

/**
 * Рассчитывает сумму кредита с учетом коммиссии
 *
 * @param requestedAmount сумма кредита, запрошенная заемщиком
 * @param disbursementFee комиссия, %
 * @param disbursementFeeConstant комиссия, стандартный сбор
 * @returns
 */
function calculateLoanWithFee(
  requestedAmount: number,
  disbursementFee: number,
  disbursementFeeConstant: number
): number {
  const processingFee = calculateFee(
    requestedAmount,
    disbursementFee,
    disbursementFeeConstant
  );

  return requestedAmount + processingFee;
}

/**
 * Рассчитывает сумму ежемесячного платежа
 *
 * @param requestedAmount сумма кредита, запрошенная заемщиком
 * @param paymentsNumber количество платежей
 * @param interestRate месячная процентная ставка %
 * @param disbursementFee комиссия, %
 * @param disbursementFeeConstant комиссия, стандартный сбор
 * @returns
 */
export function calculateMonthlyPayment(
  requestedAmount: number,
  paymentsNumber: number,
  interestRate: number,
  disbursementFee: number,
  disbursementFeeConstant: number
): number {
  const interestRateFraction = interestRate / 100;

  const loanPrincipal = calculateLoanWithFee(
    requestedAmount,
    disbursementFee,
    disbursementFeeConstant
  );

  if (interestRate === 0) {
    return Math.ceil(loanPrincipal / paymentsNumber);
  }

  const monthlyPaymentRaw =
    (loanPrincipal *
      interestRateFraction *
      (1 + interestRateFraction) ** paymentsNumber) /
    ((1 + interestRateFraction) ** paymentsNumber - 1);

  // round up
  const monthlyPaymentRounded = Math.ceil(monthlyPaymentRaw);

  return monthlyPaymentRounded;
}
