import { environment } from '~app/environment';

import {
  webRequestClient,
  type RequestParams,
  type ScpClient,
} from '~shared/scp-client';

import type {
  ApplicationApi,
  CreateApplicationPayload,
  SubmitApplicationPayload,
  UpdateApplicationPayload,
} from './application-api';

import type { Application } from '../application';

type Options = { host: string };

export class GatewayApplicationApi implements ApplicationApi {
  scpClient: ScpClient;
  host: string;

  constructor(scpClient: ScpClient, options: Options) {
    this.scpClient = scpClient;
    this.host = options.host;
  }

  findOrCreateApplication(
    payload: CreateApplicationPayload
  ): Promise<Application> {
    return this.makeRequest<Application>({
      method: 'find-or-create',
      payload,
    });
  }

  getApplicationById(applicationId: string) {
    return this.makeRequest<Application>({
      method: 'get',
      payload: {
        applicationId,
      },
    });
  }

  updateApplication(payload: Partial<UpdateApplicationPayload>): Promise<any> {
    return this.makeRequest<Application>({
      method: 'update',
      payload,
    });
  }

  submitApplication(payload: SubmitApplicationPayload): Promise<any> {
    return this.makeRequest<Application>({
      method: 'submit',
      payload,
    });
  }

  private makeRequest<T>(params: Partial<RequestParams> & { method: string }) {
    return this.scpClient
      .call<T>({
        host: this.host,
        version: 'v1',
        domain: 'origination',
        service: 'web/pos-online/applications',
        withCredentials: true,
        ...params,
      })
      .then((response) => response.payload);
  }
}

export const gatewayApplicationApi = new GatewayApplicationApi(
  webRequestClient,
  {
    host: environment.API_HOST,
  }
);
