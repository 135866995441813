import type { BooleanFinanceFieldsValue } from './boolean-finance-fields';

export function getFinancesDetailsOptions(
  debtInfo: BooleanFinanceFieldsValue[]
) {
  const financesDetailsOptions: Array<{
    value: BooleanFinanceFieldsValue;
    label: string;
  }> = [
    {
      label: 'I have a bank account',
      value: 'haveBankAccount',
    },
    {
      label: 'I used loans, microloans, or credit cards before',
      value: 'creditUsage',
    },
  ];

  const hasCredit = debtInfo.includes('creditUsage');

  if (hasCredit) {
    financesDetailsOptions.push({
      label: 'I had past due payments before',
      value: 'haveProblemDebtBefore',
    });
    financesDetailsOptions.push({
      label: 'I currently have past due payments',
      value: 'haveProblemDebtNow',
    });
    financesDetailsOptions.push({
      label: 'I have an outstanding debt',
      value: 'haveOutstandingDebtNow',
    });
  }

  return financesDetailsOptions;
}
