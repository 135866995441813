import type { FieldValues, Path, UseFormReturn } from 'react-hook-form';

import { useOnMountEffect } from './use-on-mount-effect';

export function useFormInitialize<T extends FieldValues>(
  useForm: UseFormReturn<T, any>,
  initialValue?: Partial<T>
) {
  useOnMountEffect(() => {
    const { setValue, getValues } = useForm;
    const fields = Object.keys(getValues()) as Path<T>[];

    fields.forEach((field) => {
      const initialFieldValue = initialValue?.[field];
      /**
       * useForm.watch doesn't emit initial field values => on first screen render
       * conditional fields are not displayed => unregistered => their initial value
       * is resetted to undefined => need to set initial field values manually
       *
       * alse revalidation is not triggered for initial field values => we should validate
       * them manually if they are not empty
       */
      if (initialFieldValue) {
        setValue(field, initialFieldValue, { shouldValidate: true });
      }
    });
  });
}
