export const personStepNames = {
  personalDetails: 'personalDetails',
  additionalPersonalDetails: 'additionalPersonalDetails',
  residentialAddress: 'residentialAddress',
  permanentAddress: 'permanentAddress',
  employmentDetails: 'employmentDetails',
  finances: 'finances',
  additionalEmploymentDetails: 'additionalEmploymentDetails',
  alternativeContacts: 'alternativeContacts',
  idDocumentType: 'idDocumentType',
  mainIdDocumentNumber: 'mainIdDocumentNumber',
  otherIdDocumentNumber: 'otherIdDocumentNumber',
  additionalDocument: 'additionalDocument',
} as const;

export const applicationStepNames = {
  loanDetails: 'loanDetails',
} as const;

export const stepNames = {
  ...applicationStepNames,
  ...personStepNames,
} as const;

export type PersonStepName =
  (typeof personStepNames)[keyof typeof personStepNames];
export type ApplicationStepName =
  (typeof applicationStepNames)[keyof typeof applicationStepNames];

export type StepName = (typeof stepNames)[keyof typeof stepNames];
