import type { AbortError, HttpError, TimeoutError } from './errors';

export const isHttpError = (error: Error): error is HttpError => {
  return (error as Error).name === 'HttpError';
};

export const isAbortError = (error: Error): error is AbortError => {
  return (error as Error).name === 'AbortError';
};

export const isTimeoutError = (error: Error): error is TimeoutError => {
  return (error as Error).name === 'TimeoutError';
};

export const isNetworkFail = (error: Error) => {
  return (
    isTimeoutError(error) || typeof (error as HttpError).status === 'undefined'
  );
};

export const isServerError = (error: Error): error is HttpError => {
  return isHttpError(error) && error.status >= 500;
};

export const isAuthError = (error: Error): error is HttpError => {
  return isHttpError(error) && error.status === 401;
};

export const isForbiddenError = (error: Error): error is HttpError => {
  return isHttpError(error) && error.status === 403;
};
