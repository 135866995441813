import type { MoneyProps } from '@breeze-platform-ui/money';
import { formatMoney } from '@breeze-platform-ui/money/utils';

export function formatPrice(
  price: number,
  options: MoneyProps = { showSmalls: false }
) {
  const { smalls, numerals } = formatMoney({
    value: price,
    ...options,
  });

  const numeralsPart = numerals.join(',');
  const currency = '₱';

  return `${currency}${numeralsPart}${smalls || ''}`;
}
