import { useMemo, useReducer, useState, useCallback } from 'react';

import { FormLoadingPage } from '~widgets/form-loading-page';

import { usePhonesUnicityValidation } from '~features/phones-unicity';

import type { Order } from '~entities/order';

import { StepFlowStrategy } from '~shared/lib/step-flow-strategy';
import { nonNullableValue } from '~shared/types/non-nullable-value';
import { ScrollIntoView } from '~shared/ui/scroll-into-view';
import { Step, Stepper } from '~shared/ui/stepper';

import { AuthGuard } from '~demo/features/auth-guard/auth-guard';
import { AdditionalDocument } from '~demo/widgets/application-form/additional-document';
import { AdditionalPersonalDetails } from '~demo/widgets/application-form/additional-personal-details';
import { AlternativeContacts } from '~demo/widgets/application-form/alternative-contacts';
import {
  AdditionalEmploymentDetails,
  EmploymentDetails,
} from '~demo/widgets/application-form/employment';
import type { EmployedWithCompanyStatusValue } from '~demo/widgets/application-form/employment/lib';
import { Finances } from '~demo/widgets/application-form/finances';
import {
  IdDocumentNumber,
  IdDocumentType,
} from '~demo/widgets/application-form/id-document';
import { LoanCalculator } from '~demo/widgets/application-form/loan-calc';
import { PermanentAddress } from '~demo/widgets/application-form/permanent-address';
import { PersonalDetails } from '~demo/widgets/application-form/personal-details';
import { ResidentialAddress } from '~demo/widgets/application-form/residential-address';

import type { FormValues, StepName, FormErrors } from '../../lib';
import { stepNames } from '../../lib';
import {
  formStateReducer,
  formStateActions,
  StepFlowMachine,
} from '../../model';

const initialStep = stepNames.loanDetails;

type Props = {
  order: Order;
  clientPhone?: string;
  onLogin: () => void;
  onSubmit: () => void;
  isLoading?: boolean;
};

export const LoanApplicationForm: React.FC<Props> = ({
  isLoading,
  clientPhone,
  order,
  onSubmit,
  onLogin,
}) => {
  const [currentStep, setCurrentStep] = useState<StepName>(initialStep);
  const [formState, dispatch] = useReducer(formStateReducer, {
    values: {},
    errors: {},
  });

  const stepFlowStrategy = useMemo(
    () => new StepFlowStrategy<StepName>(StepFlowMachine(initialStep)),
    []
  );

  const goNext = (currentStepValues: FormValues[StepName]) => {
    const nextStep = stepFlowStrategy.next({
      ...formState.values,
      [currentStep]: currentStepValues,
    });
    setCurrentStep(nextStep);
  };

  const goBack = () => {
    const prevScreenName = stepFlowStrategy.prev();
    setCurrentStep(prevScreenName);
  };

  const handleFormStateUpdate = (
    currentStepValues: Partial<FormValues[StepName]>,
    currentStepErrors: Partial<FormErrors[StepName]>
  ) => {
    dispatch(
      formStateActions.update({
        step: currentStep,
        value: currentStepValues,
        errors: currentStepErrors,
      })
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleFormChange = () => {};

  const handleGoBack = (
    currentStepValues: Partial<FormValues[StepName]>,
    currentStepErrors: FormErrors[StepName]
  ) => {
    handleFormStateUpdate(currentStepValues, currentStepErrors);
    goBack();
  };

  const handleStepSubmit = (currentStepValues: FormValues[StepName]) => {
    handleFormStateUpdate(currentStepValues, {});
    goNext(currentStepValues);
  };

  const handleFinalSubmit = async (currentStepValues: FormValues[StepName]) => {
    handleFormStateUpdate(currentStepValues, {});
    onSubmit();
  };
  const { additionalEmploymentDetails, alternativeContacts } = formState.values;

  const { validatePhone, handlePhoneUpdate } = usePhonesUnicityValidation({
    personal: clientPhone,
    work: additionalEmploymentDetails?.workPhone,
    alternative: alternativeContacts?.number,
    additional: alternativeContacts?.additionalPhone,
  });

  const handleLogin = useCallback(async () => {
    onLogin();
  }, [onLogin]);

  if (isLoading) {
    return <FormLoadingPage />;
  }

  const currentIdType =
    formState.values[stepNames.idDocumentType]?.typeMain ||
    formState.values[stepNames.idDocumentType]?.typeOther;

  return (
    <AuthGuard
      phoneNumber={clientPhone}
      skipCheck={currentStep === 'loanDetails'}
      onLogin={handleLogin}
      onCancelLogin={() => setCurrentStep(initialStep)}
    >
      <Stepper current={currentStep}>
        <Step name={stepNames.loanDetails}>
          <ScrollIntoView>
            <LoanCalculator order={order} onSubmit={handleStepSubmit} />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.personalDetails}>
          <ScrollIntoView>
            <PersonalDetails
              initialValue={formState.values[stepNames.personalDetails]}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.additionalPersonalDetails}>
          <ScrollIntoView>
            <AdditionalPersonalDetails
              initialValue={
                formState.values[stepNames.additionalPersonalDetails]
              }
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.residentialAddress}>
          <ScrollIntoView>
            <ResidentialAddress
              initialValue={formState.values[stepNames.residentialAddress]}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.permanentAddress}>
          <ScrollIntoView>
            <PermanentAddress
              initialValue={formState.values[stepNames.permanentAddress]}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.employmentDetails}>
          <ScrollIntoView>
            <EmploymentDetails
              initialValue={formState.values[stepNames.employmentDetails]}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.additionalEmploymentDetails}>
          <ScrollIntoView>
            <AdditionalEmploymentDetails
              validatePhoneUnicity={validatePhone}
              initialValue={
                formState.values[stepNames.additionalEmploymentDetails]
              }
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
              employmentStatus={
                nonNullableValue(
                  formState.values.employmentDetails?.employmentStatus
                ) as EmployedWithCompanyStatusValue
              }
              onPhoneUpdate={handlePhoneUpdate}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.finances}>
          <ScrollIntoView>
            <Finances
              initialValue={formState.values[stepNames.finances]}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.alternativeContacts}>
          <ScrollIntoView>
            <AlternativeContacts
              validatePhoneUnicity={validatePhone}
              initialValue={formState.values[stepNames.alternativeContacts]}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
              onPhoneUpdate={handlePhoneUpdate}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.idDocumentType}>
          <ScrollIntoView>
            <IdDocumentType
              initialValue={formState.values[stepNames.idDocumentType]}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
            />
          </ScrollIntoView>
        </Step>
        {/* showed for documents with type [UMID, SSS ID] -> we should not show additional document screen for them  */}
        <Step name={stepNames.mainIdDocumentNumber}>
          <ScrollIntoView>
            <IdDocumentNumber
              initialValue={formState.values[stepNames.mainIdDocumentNumber]}
              currentIdType={nonNullableValue(currentIdType)}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleFinalSubmit}
            />
          </ScrollIntoView>
        </Step>
        {/* showed for documents with other id types -> we should show additional document screen for them */}
        <Step name={stepNames.otherIdDocumentNumber}>
          <ScrollIntoView>
            <IdDocumentNumber
              initialValue={formState.values[stepNames.otherIdDocumentNumber]}
              currentIdType={nonNullableValue(currentIdType)}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleStepSubmit}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepNames.additionalDocument}>
          <ScrollIntoView>
            <AdditionalDocument
              initialValue={formState.values[stepNames.additionalDocument]}
              onPrev={handleGoBack}
              onFieldCompleted={handleFormChange}
              onSubmit={handleFinalSubmit}
            />
          </ScrollIntoView>
        </Step>
      </Stepper>
    </AuthGuard>
  );
};
