import { environment } from '~app/environment';

import { webRequestClient, type ScpClient } from '~shared/scp-client';

import type {
  AddressSuggestionApi,
  AddressSuggestionParams,
} from './address-suggestions-api';

import type { Address } from '../address';

interface Params {
  scpClient: ScpClient;
  host: string;
}

export class GatewayAddressSuggestionApi implements AddressSuggestionApi {
  client: ScpClient;
  host: string;

  constructor({ scpClient, host }: Params) {
    this.client = scpClient;
    this.host = host;
  }

  getAddressSuggestion(params: AddressSuggestionParams) {
    return this.makeRequest<Address[]>({
      method: 'suggestion',
      payload: params,
    });
  }

  private makeRequest<T>({
    payload,
    method,
  }: {
    payload: AddressSuggestionParams;
    method: string;
  }) {
    return this.client
      .call<{ items: T }>({
        host: this.host,
        version: 'v1',
        domain: 'delivery',
        service: 'delivery/address',
        method,
        payload,
        withCredentials: true,
        withLeadingDomain: true,
      })
      .then((response) => response.payload.items);
  }
}

export const gatewayAddressSuggestionApi = new GatewayAddressSuggestionApi({
  host: environment.API_HOST,
  scpClient: webRequestClient,
});
