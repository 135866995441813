import type { ComponentProps } from 'react';

import BreezeButton from '@breeze-platform-ui/button';

export const Button: React.FC<
  ComponentProps<typeof BreezeButton> & { bold?: boolean }
> = ({ children, bold = true, ...props }) => (
  <BreezeButton {...props}>
    <span className={bold ? 'font-bold' : ''}>{children}</span>
  </BreezeButton>
);
