import type { AbstractOption } from '~shared/types/abstract-option';

const options = [
  {
    value: 'EMPLOYER',
    title: 'Employer',
  },
  {
    value: 'COWORKER',
    title: 'Co-worker',
  },
  {
    value: 'CLIENT',
    title: 'Client',
  },
  {
    value: 'SUPPLIER',
    title: 'Supplier',
  },
  {
    value: 'EMPLOYEE',
    title: 'Employee',
  },
  {
    value: 'FAMILY_MEMBER',
    title: 'Family member',
  },
  {
    value: 'OTHER',
    title: 'Other',
  },
] as const;

export type ReferenceSelfEmployedValue = (typeof options)[number]['value'];
export const referenceOptionsSelfEmployed =
  options as unknown as AbstractOption[];
