import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  Route,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { AppLayout } from '~app/app-layout';
import { environment } from '~app/environment';

import { ApplicationStatus } from '~pages/application-status/application-status';
import { NotFound } from '~pages/not-found/not-found';
import { OrderPage } from '~pages/order';

import { AuthGuard } from '~features/auth-guard/auth-guard';
import { WithSession } from '~features/with-session/with-session';

import * as Sentry from '@breeze/web-lib-sentry';
import { findOrCreateWuid } from '@breeze/web-lib-wuid';
import { DemoPage } from '~demo/page';

import './globals.css';
import './index.css';

Sentry.init({
  dsn: environment.SENTRY_HOST,
  release: environment.APP_VERSION,
  environment: environment.ENV,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),

    Sentry.replayIntegration(),
  ],

  tracesSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
});

Sentry.setUser({ id: findOrCreateWuid() });

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <AppLayout>
    <SentryRoutes>
      <Route path="demo" element={<DemoPage />} />
      <Route path="" element={<WithSession />}>
        <Route path="order/:orderId" element={<OrderPage />} />
        <Route
          path="applications/:applicationId"
          element={
            <AuthGuard>
              <ApplicationStatus />
            </AuthGuard>
          }
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </SentryRoutes>
  </AppLayout>
);
