import type { Validate } from 'react-hook-form';

const pattern = /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;

interface IsDateOptions {
  text?: string;
}

function isValidDate(year: number, month: number, day: number) {
  // Set date to make function independent of the current date
  const date = new Date(1960, 0, 1, 0, 0, 0, 0);

  date.setFullYear(year);
  date.setMonth(month);
  date.setDate(day);

  return (
    date.getFullYear() === year &&
    date.getMonth() === month &&
    date.getDate() === day
  );
}

/**
 * checks if string is date in format MM/DD/YYYY
 */
export function isDate(options?: IsDateOptions): Validate<unknown> {
  return function isDateValidator(value) {
    const { text = 'The date format is MM/DD/YYYY' } = options ?? {};

    const isValidFormat =
      typeof value === 'string' && value.match(pattern) !== null;

    if (!isValidFormat) {
      return text;
    }

    const [m, d, y] = value.split('/');
    // Months are indexed from zero
    const isValid = isValidDate(+y, +m - 1, +d);

    if (!isValid) {
      return text;
    }

    return undefined;
  };
}
