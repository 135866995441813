import { atomWithListeners } from '~shared/lib/atom-with-listeners/atom-with-listeners';

import isEmpty from '@tinkoff/utils/is/empty';

import { atom } from 'jotai';

import type { FormErrors, FormValues, StepName } from '../lib';

export const formValuesAtom = atom({} as FormValues);
export const formErrorsAtom = atom({});
export const isInitializationInProgress = atom(false);
export const [recoveredInitialStepAtom, useRecoveredInitialStepListener] =
  atomWithListeners<StepName | null>(null);

export const updateFormAtom = atom(
  null,
  (
    get,
    set,
    payload: {
      step: StepName;
      value: Partial<FormValues[StepName]>;
      errors?: FormErrors[StepName];
    }
  ) => {
    set(formValuesAtom, {
      ...get(formValuesAtom),
      [payload.step]: payload.value,
    });
    set(formErrorsAtom, {
      ...get(formErrorsAtom),
      [payload.step]: payload.errors,
    });
  }
);

export const initializeFormAtom = atom(null, (_get, set) =>
  set(isInitializationInProgress, true)
);

export const initializeFormSuccessAtom = atom(
  null,
  (
    get,
    set,
    {
      values,
      recoveredInitialStep,
    }: { values: Partial<FormValues>; recoveredInitialStep: StepName | null }
  ) => {
    const formValues = Object.keys(values).reduce((result, key) => {
      const stepName = key as StepName;

      if (!isEmpty(values[stepName])) {
        return { ...result, [stepName]: values[stepName] };
      }

      return result;
    }, get(formValuesAtom));

    set(formValuesAtom, formValues);

    if (recoveredInitialStep) {
      set(recoveredInitialStepAtom, recoveredInitialStep);
    }

    set(isInitializationInProgress, false);
  }
);
