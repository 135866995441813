import { Controller, useFormContext } from 'react-hook-form';

import { combineValidators } from '~shared/lib/combine-validators';
import { validators } from '~shared/validators';

import { Input, RadioGroup } from '@breeze/rhf-adapters';

import type { FormValues } from './employment-details';

import { unemployedReasonOptions } from '../../lib';

export const UnemployedReason: React.FC = () => {
  const { control, watch } = useFormContext<FormValues>();
  const unemployedReason = watch('unemployedReason');

  return (
    <>
      <Controller
        name="unemployedReason"
        shouldUnregister
        control={control}
        rules={{
          required: 'Select an option',
        }}
        render={(fieldProps) => (
          <RadioGroup
            {...fieldProps}
            qaLabel="unemployed-reason-radio"
            options={unemployedReasonOptions}
            vertical
            wide
          />
        )}
      />
      {unemployedReason === 'Other' && (
        <Controller
          name="unemployedReasonOther"
          shouldUnregister
          control={control}
          rules={{
            validate: combineValidators(
              validators.required({
                text: 'Enter the reason',
              }),
              validators.maxLength({ maxLength: 150 }),
              validators.latinOnly()
            ),
          }}
          render={(fieldProps) => (
            <Input label="Other reason" {...fieldProps} />
          )}
        />
      )}
    </>
  );
};
