import { FormProvider, useForm } from 'react-hook-form';

import type { SetupLoanFormValues } from '~features/setup-loan';
import { SetupLoan } from '~features/setup-loan/ui/setup-loan';

import { type OrderItem } from '~entities/order';
import type { Order } from '~entities/order';
import { OrderItemUI } from '~entities/order/ui/order-item/order-item';

import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Island } from '~shared/ui/island';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

import type { LoanCalculatorFormValues } from '.';

export type FormValues = {
  items: OrderItem[];
} & SetupLoanFormValues;
type Props = {
  order: Order;
  onSubmit: (values: FormValues) => void;
};

export const LoanCalculator: React.FC<Props> = ({ order, onSubmit }) => {
  const methods = useForm<LoanCalculatorFormValues>({
    mode: 'all',
    defaultValues: { items: order.orderItems },
  });

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <Screen header={<HeaderWithLogo />}>
        <div className="flex flex-col gap-5">
          <ScreenTitle title="Let’s calculate your loan" />
          <Island>
            {order.orderItems.map((item) => (
              <OrderItemUI item={item} key={item.name} />
            ))}
          </Island>
          <Island>
            <FormProvider {...methods}>
              <SetupLoan
                totalPrice={order.paymentAmount}
                loanParams={order.product}
              />
            </FormProvider>
          </Island>
        </div>
      </Screen>
    </form>
  );
};
