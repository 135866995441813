import type { AbstractOption } from '~shared/types/abstract-option';

/**
 * TODO: should turn string values into
 * enum values
 */
const options = [
  {
    title: 'Accounting and Finance',
    value: 'Accounting and Finance',
  },
  {
    title: 'Banking and Accounting',
    value: 'Banking and Accounting',
  },
  {
    title: 'Construction and Manufacturing',
    value: 'Construction and Manufacturing',
  },
  {
    title: 'Customer Service and BPO',
    value: 'Customer Service and BPO',
  },
  {
    title: 'Education and Training',
    value: 'Education and Training',
  },
  {
    title: 'Food and Restaurants',
    value: 'Food and Restaurants',
  },
  {
    title: 'Government and NGOs',
    value: 'Government and NGOs',
  },
  {
    title: 'Health Care and Animal Care',
    value: 'Health Care and Animal Care',
  },
  {
    title: 'Hospitality and Tourism',
    value: 'Hospitality and Tourism',
  },
  {
    title: 'IT and Software',
    value: 'IT and Software',
  },
  {
    title: 'Transportation, Delivery, and Logistics',
    value: 'Transportation, Delivery, and Logistics',
  },
  {
    title: 'Safety and Security',
    value: 'Safety and Security',
  },
  {
    title: 'Sales, Marketing, and Retail',
    value: 'Sales, Marketing, and Retail',
  },
  {
    title: 'Other',
    value: 'Other',
  },
] as const;

export type NatureOfWorkValue = (typeof options)[number]['value'];
export const natureOfWorkOptions =
  options as unknown as AbstractOption<NatureOfWorkValue>[];
