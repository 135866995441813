import { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { UseControllerProps } from 'react-hook-form';

import { useDebouncedValue } from '~shared/hooks';

import type { Option } from '@breeze-platform-ui/dropdown';
import { InputAutocomplete } from '@breeze/rhf-adapters';

import type { SuggestionField } from '../api/address-suggestions-api';
import { getSuggestionRequestParams } from '../lib/get-suggestion-request-params';
import { useGetSuggestionsOptionsQuery } from '../model/address-suggestion-query';
import type { ExtendedAddressSuggestionOption } from '../model/address-suggestion-query';

type Props = Pick<UseControllerProps, 'rules'> & {
  name: string;
  label: string;
  addressValues: Record<string, string>;
  fieldNamesMap: Record<string, SuggestionField>;
  onOptionSelect: (
    option: ExtendedAddressSuggestionOption,
    shouldResetChildFields: boolean
  ) => void;
  onFieldDisregard: (name: string) => void;
};

export const AddressSuggestionItem = ({
  name,
  label,
  rules,
  addressValues,
  fieldNamesMap,
  onOptionSelect,
  onFieldDisregard,
}: Props) => {
  const { control } = useFormContext();

  const [searchQuery, setSearchQuery] = useState<string>();
  const debouncedQuery = useDebouncedValue(searchQuery, 300);

  useEffect(() => {
    setSearchQuery(addressValues[name]);
  }, [addressValues, name]);

  const requestParams = getSuggestionRequestParams({
    fieldName: name,
    searchQuery: debouncedQuery,
    fieldNamesMap,
    addressValues,
  });

  const { isLoading, data } = useGetSuggestionsOptionsQuery({
    name: fieldNamesMap[name],
    params: requestParams,
  });

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      shouldUnregister
      render={(rp) => (
        <InputAutocomplete
          {...rp}
          // chrome ignores autocomplete=off for fields with common names(like province, password, etc)
          // -> we need to provide nonexistent autoComplete to turn it off
          autoComplete="address-suggestion"
          minDropdownShowLength={0}
          smartDirection
          label={label}
          loading={isLoading}
          suggestions={data?.options || []}
          onInput={(event) => setSearchQuery(event.target.value)}
          onBlur={(_event, params) => {
            const { source } = params;
            if (source === 'filterChange') {
              onFieldDisregard(name);
            }
          }}
          onOptionSelect={(_e: null, params: { option: Option }) => {
            const option = params.option as ExtendedAddressSuggestionOption;
            onOptionSelect(option, data?.shouldResetChildFields || false);
          }}
        />
      )}
    />
  );
};
