import type {
  PersonFormatter,
  PersonRecovery,
  PersonScreensMapping,
} from '~entities/person';
import {
  formatPersonValuesByMapping,
  recoverPersonValuesByMapping,
} from '~entities/person';

import type { EmploymentDetailsFormValues } from '..';

const employmentDetailsMapping: PersonScreensMapping<EmploymentDetailsFormValues> =
  {
    employmentStatus: { path: ['work', 'employmentStatus'] },
    jobTitle: { path: ['work', 'jobTitle'] },
    natureOfWork: { path: ['work', 'natureOfWork'] },
    natureOfWorkOther: { path: ['work', 'natureOfWorkOther'] },
    workExperience: { path: ['work', 'workExperience'] },
    companyName: { path: ['work', 'companyName'] },
    unemployedReason: { path: ['work', 'unemployedReason'] },
    unemployedReasonOther: {
      path: ['work', 'unemployedReasonOther'],
    },
  };

export const formatEmploymentDetails: PersonFormatter<
  EmploymentDetailsFormValues
> = (application, screenValues) => {
  return formatPersonValuesByMapping(
    application,
    screenValues,
    employmentDetailsMapping
  );
};

export const recoverEmploymentDetails: PersonRecovery<
  EmploymentDetailsFormValues
> = (application) => {
  return recoverPersonValuesByMapping(application, employmentDetailsMapping);
};
