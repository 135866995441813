import type { PropsWithChildren } from 'react';
import React from 'react';

import { AuthByPhone } from '~widgets/auth-by-phone/auth-by-phone';
import { RepeatedAuth } from '~widgets/repeated-auth/repeated-auth';

import {
  isAuthorisedAtom,
  isFirstAuthAtom,
  userPhoneAtom,
} from '~entities/auth';

import { ScrollIntoView } from '~shared/ui/scroll-into-view';

import { useAtomValue } from 'jotai';

type Props = {
  phoneNumber?: string;
  skipCheck?: boolean;
  onLogin?: (phone: string) => void;
  onCancel?: () => void;
};

export const AuthGuard: React.FC<PropsWithChildren & Props> = React.memo(
  ({ children, phoneNumber, skipCheck, onLogin, onCancel }) => {
    const isAuthorised = useAtomValue(isAuthorisedAtom);
    const lastUserPhoneNumber = useAtomValue(userPhoneAtom);
    const isFirstAuth = useAtomValue(isFirstAuthAtom);

    if (skipCheck) {
      return children;
    }

    if (!isAuthorised) {
      // if not first auth -> show client confirmation with warning instead
      return (
        <ScrollIntoView>
          {isFirstAuth || !lastUserPhoneNumber ? (
            <AuthByPhone
              initialPhone={phoneNumber ?? lastUserPhoneNumber ?? undefined}
              onLogin={onLogin}
              onCancel={onCancel}
            />
          ) : (
            <RepeatedAuth phone={lastUserPhoneNumber} onLogin={onLogin} />
          )}
        </ScrollIntoView>
      );
    }

    return children;
  }
);
