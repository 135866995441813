import type {
  PersonFormatter,
  PersonScreensMapping,
  PersonRecovery,
} from '~entities/person';
import { recoverPersonValuesByMapping } from '~entities/person';
import { formatPersonValuesByMapping } from '~entities/person';

import {
  formatDate,
  formatYesNo,
  recoverDate,
  recoverYesNo,
} from '~shared/formatters';

import type { FormValues } from '../ui/additional-personal-details';

const mapping: PersonScreensMapping<FormValues> = {
  birthDate: {
    path: ['birthDate'],
    format: formatDate,
    recover: recoverDate,
  },
  placeOfBirth: { path: ['placeOfBirth'] },
  gender: { path: ['gender'] },
  civilStatus: { path: ['civilStatus'] },
  educationLevel: { path: ['educationLevel'] },
  preferredLanguages: { path: ['preferredLanguages'] },
  abroadTraveller: {
    path: ['abroadTraveller'],
    format: formatYesNo,
    recover: recoverYesNo,
  },
};

export const formatAdditionalPersonalDetails: PersonFormatter<FormValues> = (
  person,
  screenValues
) => {
  return formatPersonValuesByMapping(person, screenValues, mapping);
};

export const recoverAdditionalPersonalDetails: PersonRecovery<FormValues> = (
  person
) => {
  return recoverPersonValuesByMapping(person, mapping);
};
