import type {
  PersonFormatter,
  PersonScreensMapping,
  PersonRecovery,
} from '~entities/person';
import {
  recoverPersonValuesByMapping,
  formatPersonValuesByMapping,
} from '~entities/person';

import type { FormValues } from '../types';

const mapping: PersonScreensMapping<FormValues> = {
  firstName: { path: ['name', 'firstName'] },
  lastName: { path: ['name', 'lastName'] },
  middleName: { path: ['name', 'middleName'] },
  citizenship: { path: ['citizenship'] },
  email: { path: ['email'] },
  nickname: { path: ['name', 'preferredName'] },
};

export const formatPersonalDetails: PersonFormatter<FormValues> = (
  person,
  screenValues
) => {
  return formatPersonValuesByMapping(person, screenValues, mapping);
};

export const recoverPersonalDetails: PersonRecovery<FormValues> = (person) => {
  return recoverPersonValuesByMapping(person, mapping);
};
