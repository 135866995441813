import type { Application } from '~entities/application';

import type { RecoveryMapping } from '~shared/formatters/mappings';
import { formatByMapping, recoverByMapping } from '~shared/formatters/mappings';

export type ApplicationFormatter<T> = {
  (
    application: Partial<Application>,
    values: Partial<T>,
    formValues?: Record<string, unknown>
  ): Partial<Application>;
};

export type ApplicationRecovery<StepValues> = (
  application: Application
) => Partial<StepValues> | undefined | void;

export type ApplicationRecoveryMapping<T> = RecoveryMapping<T, Application>;

export function recoverApplicationValuesByMapping<T>(
  application: Application,
  mapping: ApplicationRecoveryMapping<T>
) {
  return recoverByMapping(application, mapping);
}

export function formatApplicationValuesByMapping<
  StepValues extends Record<string, any>
>(
  application: Partial<Application>,
  stepValues: Partial<StepValues>,
  mapping: ApplicationRecoveryMapping<StepValues>
) {
  return formatByMapping(application, stepValues, mapping);
}
