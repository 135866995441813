import { useEffect, useState } from 'react';

import { ApplicationStatusUI, type Application } from '~entities/application';

import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Loader } from '~shared/ui/loader';
import { Screen } from '~shared/ui/screen';

import { subSeconds } from 'date-fns';

import { applicationInProgressMock } from '../../mocks/application-in-progress';

export const ApplicationDetails: React.FC = () => {
  const [application, setApplication] = useState<Application>({
    ...applicationInProgressMock,
    applicationStatusChangedUTC: subSeconds(new Date(), 1).toISOString(),
  });

  useEffect(() => {
    setTimeout(
      () =>
        setApplication((app) => ({
          ...app,
          qualified: true,
        })),
      3000
    );
  }, []);

  return (
    <Screen header={<HeaderWithLogo />}>
      {application ? (
        <ApplicationStatusUI {...application} />
      ) : (
        <Loader centered />
      )}
    </Screen>
  );
};
