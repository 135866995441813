import type { EmploymentStatusValue } from './employment-status-options';

export const jobTitleLabels: Record<
  Exclude<EmploymentStatusValue, 'UNEMPLOYED'>,
  string
> = {
  FULL_TIME: 'Job title',
  PART_TIME: 'Job title',
  CONTRACTUAL_FREELANCER: 'Work activity or job title',
  DOMESTIC_WORKER: 'Work activity or job title',
  OWN_BUSINESS: 'Your role',
  OFW: 'Job title',
};

export const jobTitleMessages: Record<
  Exclude<EmploymentStatusValue, 'UNEMPLOYED'>,
  string
> = {
  FULL_TIME: 'Enter your job title',
  PART_TIME: 'Enter your job title',
  CONTRACTUAL_FREELANCER: 'Enter your work activity or job title',
  DOMESTIC_WORKER: 'Enter your work activity or job title',
  OWN_BUSINESS: 'Enter your role',
  OFW: 'Enter your job title',
};
