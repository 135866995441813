import isEmpty from '@tinkoff/utils/is/empty';

import type { FormValues, StepName } from '../lib';
import type { FormErrors } from '../lib/form-values';

type RecoverAction = {
  type: 'RECOVER';
  payload: Partial<FormValues>;
};
type UpdateAction = {
  type: 'UPDATE';
  payload: {
    step: StepName;
    value: Partial<FormValues[StepName]>;
    errors?: FormErrors[StepName];
  };
};

type ActionCreator<T extends { type: string; payload: any }> = (
  payload: T['payload']
) => T;

const recover: ActionCreator<RecoverAction> = (payload) => ({
  type: 'RECOVER',
  payload,
});

const update: ActionCreator<UpdateAction> = (payload) => ({
  type: 'UPDATE',
  payload,
});

type FormState = {
  values: Partial<FormValues>;
  errors: FormErrors;
};

export const formStateActions = { recover, update };

export const formStateReducer = (
  formState: FormState,
  action: RecoverAction | UpdateAction
): FormState => {
  switch (action.type) {
    case 'UPDATE':
      return {
        values: {
          ...formState.values,
          [action.payload.step]: action.payload.value,
        },
        errors: {
          ...formState.errors,
          [action.payload.step]: action.payload.errors,
        },
      };
    case 'RECOVER': {
      const values = action.payload;

      const formValues = Object.keys(values).reduce((result, key) => {
        const stepName = key as StepName;

        if (!isEmpty(values[stepName])) {
          return { ...result, [stepName]: values[stepName] };
        }

        return result;
      }, formState.values);

      return { values: formValues, errors: formState.errors };
    }
  }
};
