import { Col } from '@breeze-platform-ui/layout';
import { NotificationInline } from '@breeze-platform-ui/notification';

type Props = {
  error?: Error;
};

export const AuthorizationError: React.FC<Props> = () => {
  return (
    <Col gaps={16} alignCross="stretch">
      <NotificationInline
        showClose={false}
        type="warning"
        timer={false}
        animateFirstRender={false}
      >
        <Col gaps={10}>
          <p className="text-sm font-bold">
            Failed to&nbsp;verify the&nbsp;number
          </p>
          <p className="text-s">
            Something went wrong. Please, try again later.
          </p>
        </Col>
      </NotificationInline>
    </Col>
  );
};
