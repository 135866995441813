import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Loader } from '~shared/ui/loader';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

interface Props {
  headerText?: string;
  title?: string;
}

export const FormLoadingPage: React.FC<Props> = ({ title = '' }) => {
  return (
    <Screen header={<HeaderWithLogo />}>
      <ScreenTitle title={title} />
      <Loader />
    </Screen>
  );
};
