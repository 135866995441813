import styles from './basic-header.module.css';

type Props = {
  actionButton?: React.ReactNode;
  mainContent?: React.ReactNode;
  secondaryContent?: React.ReactNode;
};

/**
 * Header component for basic header layout
 * @param actionButton - button, displayed on the left side of the header
 * @param mainContent - usually text, describing the current page
 * @param secondaryContent - any additional content, displayed on the right side
 */
export const BasicHeader: React.FC<Props> = ({
  actionButton,
  mainContent,
  secondaryContent,
}) => {
  const main =
    typeof mainContent === 'string' ? (
      <div className={styles.mainText}>{mainContent}</div>
    ) : (
      mainContent
    );

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {actionButton && <div className={styles.button}>{actionButton}</div>}
        {mainContent && main}
      </div>
      {secondaryContent}
    </div>
  );
};
