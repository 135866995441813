import type { IdDocumentType } from '~entities/person';

import type { RadioOption } from '~shared/types/radio-option';

export type MainIdDocumentTypeValue = Extract<
  IdDocumentType,
  'UMID' | 'PRC' | 'SSS'
>;
export type OtherIdDocumentTypeValue = Extract<
  IdDocumentType,
  'PHIL_ID' | 'DRIVING_LICENSE' | 'PASSPORT' | 'POSTAL_ID'
>;

export const idDocumentTypeMainOptions = [
  {
    label: 'UMID',
    value: 'UMID',
  },
  {
    label: 'SSS ID',
    value: 'SSS',
  },
  {
    label: 'PRC ID',
    value: 'PRC',
  },
] as RadioOption<MainIdDocumentTypeValue>[];

export const idDocumentTypeOtherOptions = [
  {
    label: 'Philippine passport',
    value: 'PASSPORT',
  },
  {
    label: "Driver's license",
    value: 'DRIVING_LICENSE',
  },
  {
    label: 'PhilSys ID',
    value: 'PHIL_ID',
  },
  {
    label: 'Postal ID',
    value: 'POSTAL_ID',
  },
] as RadioOption<OtherIdDocumentTypeValue>[];
