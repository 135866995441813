function isZeroDp({
  minDownPaymentPercent,
  maxDownPaymentPercent,
}: {
  minDownPaymentPercent: number;
  maxDownPaymentPercent: number;
}) {
  return minDownPaymentPercent === 0 && maxDownPaymentPercent === 0;
}

export const getMinDownPayment = (
  totalPrice: number,
  loanOptions: {
    maxLoanAmount: number;
    minDownPaymentPercent: number;
    maxDownPaymentPercent: number;
  }
) => {
  if (isZeroDp(loanOptions)) {
    return 0;
  }

  const minDownPaymentByPercent = Math.ceil(
    (totalPrice * loanOptions.minDownPaymentPercent) / 100
  );
  const minDownPaymentForMaxLoan = Math.ceil(
    totalPrice - loanOptions.maxLoanAmount
  );

  if (totalPrice - minDownPaymentByPercent > loanOptions.maxLoanAmount) {
    return minDownPaymentForMaxLoan;
  }

  return minDownPaymentByPercent;
};

export const getMaxDownPayment = (
  totalPrice: number,
  loanOptions: {
    minLoanAmount: number;
    minDownPaymentPercent: number;
    maxDownPaymentPercent: number;
  }
) => {
  if (isZeroDp(loanOptions)) {
    return 0;
  }

  const maxDownPaymentByPercent = Math.floor(
    (totalPrice * loanOptions.maxDownPaymentPercent) / 100
  );
  const maxDownPaymentForMinLoan = Math.floor(
    totalPrice - loanOptions.minLoanAmount
  );

  if (totalPrice - maxDownPaymentByPercent < loanOptions.minLoanAmount) {
    return maxDownPaymentForMinLoan;
  }

  return maxDownPaymentByPercent;
};

export const getMinPriceForDownPayment = (
  downPayment: number,
  loanOptions: {
    maxLoanAmount: number;
    minLoanAmount: number;
    minDownPaymentPercent: number;
    maxDownPaymentPercent: number;
  }
) => {
  if (isZeroDp(loanOptions)) {
    return 0;
  }

  const minPriceByPercent = Math.ceil(
    downPayment / (loanOptions.maxDownPaymentPercent / 100)
  );
  const minPriceForMinLoan = Math.ceil(downPayment + loanOptions.minLoanAmount);

  // can't calculate min price due to impossibly big downPayment
  if (minPriceByPercent - downPayment > loanOptions.maxLoanAmount) {
    return NaN;
  }

  if (minPriceByPercent - downPayment < loanOptions.minLoanAmount) {
    return minPriceForMinLoan;
  }

  return minPriceByPercent;
};

export const getMaxPriceForDownPayment = (
  downPayment: number,
  loanOptions: {
    maxLoanAmount: number;
    minLoanAmount: number;
    minDownPaymentPercent: number;
    maxDownPaymentPercent: number;
  }
) => {
  if (isZeroDp(loanOptions)) {
    return 0;
  }

  const maxPriceByPercent = Math.floor(
    downPayment / (loanOptions.minDownPaymentPercent / 100)
  );
  const maxPriceForMaxLoan = Math.floor(
    downPayment + loanOptions.maxLoanAmount
  );

  // can't calculate max price due to impossibly small downPayment
  if (maxPriceByPercent - downPayment < loanOptions.minLoanAmount) {
    return NaN;
  }

  if (maxPriceByPercent - downPayment > loanOptions.maxLoanAmount) {
    return maxPriceForMaxLoan;
  }

  return maxPriceByPercent;
};
