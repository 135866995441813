import type { Validate } from 'react-hook-form';

import isEmpty from '@tinkoff/utils/is/empty';
import isString from '@tinkoff/utils/is/string';
import trim from '@tinkoff/utils/string/trim';

const pattern = /^[\s.\-'’a-zA-ZÑñ]+$/;

interface FilipinoNameOptions {
  text: string;
}

export function filipinoName(options?: FilipinoNameOptions): Validate<unknown> {
  return function filipinoNameValidator(value) {
    const text = options?.text ?? 'Use only letters, spaces or symbols . - ’';

    if (isString(value) && !isEmpty(value) && !pattern.test(trim(value))) {
      return text;
    }

    return undefined;
  };
}
