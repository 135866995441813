import type { RadioOption } from '~shared/types/radio-option';

const options = [
  {
    label: 'UMID',
    value: 'UMID',
  },
  {
    label: 'SSS ID',
    value: 'SSS',
  },
  {
    label: 'TIN ID',
    value: 'TIN',
  },
  {
    label: 'GSIS ID',
    value: 'GSIS',
  },
] as const;

export type AdditionalIdDocumentTypeValue = (typeof options)[number]['value'];
export type AdditionalIdDocumentTypeTitle = (typeof options)[number]['label'];
export const additionalIdDocumentOptions =
  options as unknown as RadioOption<AdditionalIdDocumentTypeValue>[];
