export function plural(
  num: number,
  singularForm: string,
  pluralForm: string
): string {
  if (num === 1) {
    return singularForm;
  }

  return pluralForm;
}
