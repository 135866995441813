import { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';

import { environment } from '~app/environment';

import {
  isAuthorisedAtom,
  logoutAtom,
  useRevokeMutation,
  useRestoreSessionQuery,
  useRevalidateQuery,
} from '~entities/auth';

import { useOnMountEffect } from '~shared/hooks';
import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Loader } from '~shared/ui/loader';
import { Screen } from '~shared/ui/screen';

import { useAtomValue, useSetAtom } from 'jotai';

import { addUserInteractionsListener } from './add-user-interactions-listener';

const THROTTLING_TIME_DEFAULT = 60_000;
const thresholdMilliseconds = environment.USER_INACTIVE_THRESHOLD * 60 * 1000;

export const WithSession: React.FC = () => {
  const lastTimeActiveRef = useRef<number>(Date.now());
  const isAuthorised = useAtomValue(isAuthorisedAtom);
  const logout = useSetAtom(logoutAtom);
  const { mutateAsync: revoke } = useRevokeMutation();
  const { isLoading } = useRestoreSessionQuery();
  const { isError: isSessionExpired } = useRevalidateQuery({
    enabled: isAuthorised,
  });

  useOnMountEffect(() => {
    const trackInteraction = () => (lastTimeActiveRef.current = Date.now());
    const unsubscribe = addUserInteractionsListener(
      trackInteraction,
      THROTTLING_TIME_DEFAULT
    );

    return () => unsubscribe();
  });

  useEffect(() => {
    const interval = setInterval(async () => {
      if (isAuthorised) {
        const idleTime = Date.now() - lastTimeActiveRef.current;

        if (idleTime > thresholdMilliseconds) {
          await revoke();
          logout();
        }
      }
    }, THROTTLING_TIME_DEFAULT);

    return () => clearInterval(interval);
  }, [revoke, isAuthorised, logout]);

  useEffect(() => {
    if (isSessionExpired) {
      logout();
    }
  }, [isSessionExpired, logout]);

  if (isLoading) {
    return (
      <Screen header={<HeaderWithLogo />}>
        <Loader centered />
      </Screen>
    );
  }

  return <Outlet />;
};
