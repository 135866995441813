import type { PropsWithChildren } from 'react';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { findOrCreateWuid } from '@breeze/web-lib-wuid';

import { environment } from './environment';
import { AppQueryClientProvider } from './query-client-provider';

export const AppLayout: React.FC<PropsWithChildren> = ({ children }) => {
  window.clarity?.('set', 'user_id', findOrCreateWuid());
  window.clarity?.('set', 'app_version', environment.APP_VERSION);

  return (
    <React.StrictMode>
      <BrowserRouter>
        <AppQueryClientProvider>{children}</AppQueryClientProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};
