import type { EffectCallback } from 'react';
import { useEffect } from 'react';

export const useOnMountEffect = (onMount: EffectCallback) =>
  useEffect(() => {
    const maybeCallback = onMount();

    return () => {
      if (typeof maybeCallback === 'function') {
        maybeCallback();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
