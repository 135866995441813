export type Result = 'OK' | 'ERROR' | 'CONFIRMATION';

export interface AbstractResponse {
  result: Result;
  traceID: string;
  processingTime: string;
}

export interface Error {
  code: string;
  message: string;
}

export interface Confirmation {
  id: string;
  type: string;
  deliveryMethod: string;
  deadline: string;
}

export interface ResponseSuccess<T = null> extends AbstractResponse {
  result: 'OK';
  payload: T;
}

export interface ResponseError extends AbstractResponse {
  result: 'ERROR';
  error: Error;
}

export interface ResponseConfirmation extends AbstractResponse {
  result: 'CONFIRMATION';
  confirmation: Confirmation;
}

export type Response<T = unknown> =
  | ResponseSuccess<T>
  | ResponseConfirmation
  | ResponseError;

export function isScpResponse(
  maybeResponse: unknown
): maybeResponse is Response {
  if (typeof maybeResponse !== 'object' || maybeResponse === null) {
    return false;
  }

  if (
    (maybeResponse as Response).result !== 'OK' &&
    (maybeResponse as Response).result !== 'ERROR' &&
    (maybeResponse as Response).result !== 'CONFIRMATION'
  ) {
    return false;
  }

  if (
    (maybeResponse as Response).result === 'OK' &&
    !('payload' in maybeResponse)
  ) {
    return false;
  }

  if (
    (maybeResponse as Response).result === 'ERROR' &&
    typeof (maybeResponse as ResponseError).error !== 'object'
  ) {
    return false;
  }

  if (
    (maybeResponse as Response).result === 'CONFIRMATION' &&
    typeof (maybeResponse as ResponseConfirmation).confirmation !== 'object'
  ) {
    return false;
  }

  return true;
}

export function isSuccessResponse(
  response: Response
): response is ResponseSuccess {
  return response.result === 'OK';
}

export function isErrorResponse(response: Response): response is ResponseError {
  return response.result === 'ERROR';
}

export function isConfirmationResponse(
  response: Response
): response is ResponseConfirmation {
  return response.result === 'CONFIRMATION';
}
