import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useSetAtom } from 'jotai';

import { restoreSessionSuccessAtom } from './auth-atoms';

import type {
  AuthOtpResponse,
  AuthSuccessResponse,
  InitiateParams,
  RespondParams,
  ValidateResponse,
} from '../api';
import { gatewayAuthApi } from '../api/gateway-auth-api';

export const useInitiateMutation = (
  options?: UseMutationOptions<AuthOtpResponse, Error, InitiateParams, unknown>
) =>
  useMutation({
    mutationKey: ['initiate'],
    mutationFn: (params: InitiateParams) => gatewayAuthApi.initiate(params),
    ...options,
  });

export const useConfirmMutation = (
  options?: UseMutationOptions<
    AuthSuccessResponse,
    Error,
    RespondParams,
    unknown
  >
) =>
  useMutation({
    mutationKey: ['confirm'],
    mutationFn: (params: RespondParams) => gatewayAuthApi.respond(params),
    ...options,
  });

export const useResendConfirmationMutation = (
  options?: UseMutationOptions<AuthOtpResponse, unknown>
) =>
  useMutation({
    mutationKey: ['resend-confirmation'],
    mutationFn: () => gatewayAuthApi.resendConfirmation(),
    ...options,
  });

export const useRestoreSessionQuery = (
  options?: Omit<UseQueryOptions<ValidateResponse>, 'queryKey' | 'queryFn'>
) => {
  const restoreSession = useSetAtom(restoreSessionSuccessAtom);

  return useQuery({
    queryKey: ['validate'],
    queryFn: async () => {
      const response = await gatewayAuthApi.validate();
      restoreSession();

      return response;
    },
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useRevalidateQuery = (
  options?: Omit<
    UseQueryOptions<ValidateResponse>,
    'queryKey' | 'queryFn' | 'refetchInterval'
  >
) => {
  return useQuery({
    queryKey: ['revalidate'],
    queryFn: () => gatewayAuthApi.validate(),
    refetchInterval: (query) => {
      const refreshMinutes = query.state.data?.refreshRemainingMinutes;
      return refreshMinutes ? refreshMinutes * 60 * 1000 : false;
    },
    ...options,
  });
};

export const useRevokeMutation = (
  options?: Omit<
    UseMutationOptions<AuthSuccessResponse>,
    'queryKey' | 'queryFn'
  >
) =>
  useMutation({
    mutationKey: ['revoke'],
    mutationFn: () => gatewayAuthApi.revoke(),
    ...options,
  });
