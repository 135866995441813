import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  isAttemptsLimitReachedAtom,
  lastOtpRequestAtom,
  lastOtpRequestPhoneAtom,
  getAttemptsLimitMessage,
  getOtpRequestDelaySeconds,
  useResendCountdown,
} from '~features/otp';

import { combineValidators } from '~shared/lib/combine-validators';
import { plural } from '~shared/lib/plural';
import { ScreenTitle } from '~shared/ui/screen-title';
import { validators } from '~shared/validators';

import { LoadingButton } from '@breeze-platform-ui/button';
import Text from '@breeze-platform-ui/text';
import { InputPhone } from '@breeze/rhf-adapters';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

export type FormValues = { phone: string };

type Props = {
  onSubmit: (phoneNumber: string, promoterCode?: string) => void;
  isLoading?: boolean;
  initialPhone?: string;
};

export const ClientPhone: React.FC<Props> = ({
  onSubmit,
  isLoading,
  initialPhone,
}) => {
  const { control, handleSubmit, setError, clearErrors } = useForm<FormValues>({
    mode: 'all',
    defaultValues: { phone: initialPhone },
  });
  const isAttemptsLimitReached = useAtomValue(isAttemptsLimitReachedAtom);
  const setLastOtpRequestPhone = useSetAtom(lastOtpRequestPhoneAtom);
  const [lastOtpRequest] = useAtom(lastOtpRequestAtom);
  const [timer, startCountdown, cancelCountdown] = useResendCountdown();

  const handleFormSubmit = ({ phone }: FormValues) => {
    const otpRequestDelay = getOtpRequestDelaySeconds(lastOtpRequest);

    if (otpRequestDelay) {
      startCountdown(otpRequestDelay);
    } else {
      onSubmit(phone);
    }
  };

  useEffect(() => {
    if (initialPhone) {
      setLastOtpRequestPhone(initialPhone);
    }
  }, [initialPhone, setLastOtpRequestPhone]);

  useEffect(() => {
    const otpRequestDelay = getOtpRequestDelaySeconds(lastOtpRequest);

    if (otpRequestDelay) {
      startCountdown(otpRequestDelay);
    }
  }, [lastOtpRequest, startCountdown]);

  useEffect(() => {
    if (timer && isAttemptsLimitReached && !isLoading) {
      const otpRequestDelay = getOtpRequestDelaySeconds(lastOtpRequest);
      setError('phone', {
        message: getAttemptsLimitMessage(otpRequestDelay),
      });
    } else {
      clearErrors('phone');
    }
  }, [
    isAttemptsLimitReached,
    lastOtpRequest,
    setError,
    clearErrors,
    timer,
    isLoading,
  ]);

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="mb-5">
        <ScreenTitle title="Enter your mobile number to&nbsp;start a&nbsp;new application " />
      </div>
      <Controller
        name="phone"
        control={control}
        rules={{
          required: 'Mobile number',
          validate: combineValidators(
            validators.phoneLength(),
            validators.mobilePhoneFormat()
          ),
        }}
        render={({ field, ...props }) => (
          <InputPhone
            {...props}
            label="Mobile number"
            field={{
              ...field,
              onChange: (...args) => {
                timer && cancelCountdown();
                field.onChange(...args);
              },
            }}
          />
        )}
      />

      {!!timer && !isAttemptsLimitReached && !isLoading && (
        <div className="mb-4">
          <Text color="rgba(0, 0, 0, 0.4)" size={13}>
            Request a&nbsp;new code in&nbsp;{timer}{' '}
            {plural(timer, 'second', 'seconds')}
          </Text>
        </div>
      )}
      <LoadingButton
        loading={isLoading}
        disabled={!!timer}
        type="submit"
        size="l"
        wide
      >
        Get a code
      </LoadingButton>
    </form>
  );
};
