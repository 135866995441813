import {
  FindOrCreateApplicationErrorCode,
  isFindOrCreateApplicationError,
} from '~entities/application';

import errorImg from '~shared/images/error.webp';
import { Button } from '~shared/ui/button';
import { Result } from '~shared/ui/result';

import activeApplicationImg from './images/active-application.webp';

const createApplicationErrorDescription: Partial<
  Record<FindOrCreateApplicationErrorCode | 'Other', React.ReactNode>
> = {
  [FindOrCreateApplicationErrorCode.activeApplication]: (
    <span>
      Continue it in&nbsp;the&nbsp;app or cancel it in&nbsp;the&nbsp;in-app chat
    </span>
  ),
  [FindOrCreateApplicationErrorCode.activeLoan]: (
    <span>
      It seems like you already have an&nbsp;active Skyro loan. Come back when
      you’ve covered&nbsp;it
    </span>
  ),
  [FindOrCreateApplicationErrorCode.orderExpired]: (
    <span>
      It seems like your order has expired. Please, return to&nbsp;the&nbsp;shop
      and a&nbsp;make a&nbsp;new one
    </span>
  ),
  [FindOrCreateApplicationErrorCode.rejectionCooldown]: (
    <span>
      Due to&nbsp;your recent application for&nbsp;a&nbsp;Skyro loan, you need
      to&nbsp;wait for&nbsp;5&nbsp;days before you can reapply
    </span>
  ),
  [FindOrCreateApplicationErrorCode.submittedApplication]: (
    <span>
      Continue it in&nbsp;the&nbsp;app or cancel it in&nbsp;the&nbsp;in-app chat
    </span>
  ),
  Other: 'Something went wrong. Please, try again later',
};

export const CreateApplicationError: React.FC<{
  error: Error;
  backLink?: string;
}> = ({ error, backLink }) => {
  const status = isFindOrCreateApplicationError(error) ? error.code : 'Other';

  if (
    status === FindOrCreateApplicationErrorCode.activeApplication ||
    status === FindOrCreateApplicationErrorCode.submittedApplication
  ) {
    return (
      <Result
        title="You already have an&nbsp;application with Skyro"
        subtitle={createApplicationErrorDescription[status]}
        image={activeApplicationImg}
        footer={
          <div className="flex flex-col gap-2">
            <Button href="https://link.skyro.ph/lNBg/jtjfabpb">
              To Skyro app
            </Button>
            {backLink && (
              <Button href={backLink} theme="flat">
                Back to shop
              </Button>
            )}
          </div>
        }
      />
    );
  }

  return (
    <Result
      title="Can’t continue your application"
      subtitle={createApplicationErrorDescription[status]}
      image={errorImg}
      footer={backLink && <Button href={backLink}>Back to shop</Button>}
    />
  );
};
