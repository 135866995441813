import React from 'react';

type Props = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  icon?: React.ReactNode;
};

export const ScreenTitle: React.FC<Props> = ({ title, subtitle, icon }) => {
  return (
    <div>
      {icon && <div className="mb-5">{icon}</div>}
      {title && <h2 className="mb-1 text-2xl font-bold">{title}</h2>}
      {subtitle && <p className="text-m">{subtitle}</p>}
    </div>
  );
};
