import { useState } from 'react';
import { useForm, type FieldErrors, Controller } from 'react-hook-form';

import { useFormInitialize } from '~shared/hooks';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { Button } from '~shared/ui/button';
import { FormFooter } from '~shared/ui/form-footer';
import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

import { RadioGroup } from '@breeze/rhf-adapters';

import documentImage from '../../images/document.webp';
import { REQUIRED_TYPE_ERROR } from '../constants/field-errors';
import type {
  MainIdDocumentTypeValue,
  OtherIdDocumentTypeValue,
} from '../lib/id-document-type-options';
import {
  idDocumentTypeMainOptions,
  idDocumentTypeOtherOptions,
} from '../lib/id-document-type-options';

export type FormValues = {
  typeMain?: MainIdDocumentTypeValue;
  typeOther?: OtherIdDocumentTypeValue;
};

type Props = {
  onSubmit: (formValues: FormValues) => void;
  onFieldCompleted?: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onPrev: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  initialValue?: Partial<FormValues>;
};

export const IdDocumentType: React.FC<Props> = ({
  initialValue,
  onFieldCompleted,
  onPrev,
  onSubmit,
}) => {
  const methods = useForm<FormValues>({
    defaultValues: initialValue,
    mode: 'all',
  });
  const { control, getValues, handleSubmit, resetField, watch } = methods;
  const [showOtherTypes, setShowOtherTypes] = useState<boolean>(
    () => !!initialValue?.typeOther
  );

  useFormInitialize(methods, initialValue);

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };

  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onPrev(getValues(), actualErrors);
  };

  const handleFormSubmit = (value: FormValues) => {
    onSubmit(value);
  };

  const handleShowOtherDocsClick = () => {
    resetField('typeMain', { defaultValue: null });
    setShowOtherTypes(true);
  };

  const typeMain = watch('typeMain');

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(handleFormSubmit)}>
      <Screen
        header={<HeaderWithLogo />}
        footer={<FormFooter onClickPrev={handleClickButtonPrev} />}
      >
        <div className="mb-2 flex flex-col gap-5">
          <ScreenTitle
            title="Main identity document"
            icon={<img src={documentImage} width={56} height={56} alt="" />}
          />
          <p className="text-xl font-bold">
            These IDs increase approval rate by&nbsp;7%
          </p>
        </div>

        <Controller
          control={control}
          name="typeMain"
          rules={{
            required: showOtherTypes ? undefined : REQUIRED_TYPE_ERROR,
          }}
          render={(fieldProps) => (
            <RadioGroup
              {...fieldProps}
              field={{
                ...fieldProps.field,
                onChange: (event, params) => {
                  methods.resetField('typeOther', { defaultValue: null });
                  fieldProps.field.onChange(event, params);
                },
              }}
              qaLabel="mainIdType"
              options={idDocumentTypeMainOptions}
              vertical
              wide
            />
          )}
        />
        {!showOtherTypes && (
          <Button
            theme="outline"
            size="l"
            onClick={handleShowOtherDocsClick}
            wide
          >
            I don&apos;t have these IDs
          </Button>
        )}
        {showOtherTypes && (
          <>
            <p className="mb-2 text-xl font-bold">Other IDs</p>
            <Controller
              control={control}
              name="typeOther"
              rules={{
                validate: (typeOther) =>
                  showOtherTypes && !typeOther && !typeMain
                    ? REQUIRED_TYPE_ERROR
                    : undefined,
              }}
              render={(fieldProps) => (
                <RadioGroup
                  {...fieldProps}
                  field={{
                    ...fieldProps.field,
                    onChange: (event, params) => {
                      methods.resetField('typeMain', { defaultValue: null });
                      fieldProps.field.onChange(event, params);
                    },
                  }}
                  qaLabel="otherIdType"
                  options={idDocumentTypeOtherOptions}
                  vertical
                  wide
                />
              )}
            />
          </>
        )}
      </Screen>
    </form>
  );
};
