import type {
  PersonFormatter,
  PersonScreensMapping,
  PersonRecovery,
} from '~entities/person';
import {
  recoverPersonValuesByMapping,
  formatPersonValuesByMapping,
} from '~entities/person';

import {
  formatNumber,
  recoverByMapping,
  recoverNumber,
} from '~shared/formatters';

import isEmpty from '@tinkoff/utils/is/empty';

import type { BooleanFinanceFieldsValue } from './boolean-finance-fields';

import type { FormValues } from '../ui/finances';

type DebtInfoValues = Record<BooleanFinanceFieldsValue, boolean>;

const mapping: PersonScreensMapping<Omit<FormValues, 'debtInfo'>> = {
  financialSituation: {
    path: ['finance', 'financialSituation'],
  },
  personMonthlyIncome: {
    path: ['finance', 'personMonthlyIncome'],
    format: formatNumber,
    recover: recoverNumber,
  },
  monthlyPaymentAmount: {
    path: ['finance', 'monthlyPaymentAmount'],
    format: formatNumber,
    recover: recoverNumber,
  },
};

const debtInfoMapping: PersonScreensMapping<DebtInfoValues> = {
  haveBankAccount: { path: ['finance', 'haveBankAccount'] },
  creditUsage: { path: ['finance', 'creditUsage'] },
  haveProblemDebtBefore: {
    path: ['finance', 'haveProblemDebtBefore'],
  },
  haveProblemDebtNow: { path: ['finance', 'haveProblemDebtNow'] },
  haveOutstandingDebtNow: {
    path: ['finance', 'haveOutstandingDebtNow'],
  },
};

export const formatFinances: PersonFormatter<FormValues> = (
  person,
  screenValues
) => {
  let debtInfoValues: Partial<DebtInfoValues> = {};

  if (Array.isArray(screenValues.debtInfo)) {
    debtInfoValues = screenValues.debtInfo.reduce<Partial<DebtInfoValues>>(
      (acc, field) => {
        acc[field] = true;
        return acc;
      },
      {}
    );
  }

  const personWithDebtInfo = formatPersonValuesByMapping(
    person,
    debtInfoValues,
    debtInfoMapping
  );

  return formatPersonValuesByMapping(personWithDebtInfo, screenValues, mapping);
};

export const recoverFinances: PersonRecovery<FormValues> = (application) => {
  const values: Partial<FormValues> =
    recoverPersonValuesByMapping(application, mapping) ?? {};
  const debtInfoValues = recoverByMapping(application, debtInfoMapping) ?? {};
  const debtInfo = (
    Object.keys(debtInfoValues) as BooleanFinanceFieldsValue[]
  ).filter((key) => debtInfoValues[key]);

  if (debtInfo.length) {
    values.debtInfo = debtInfo;
  }

  if (isEmpty(values)) {
    return undefined;
  }

  return values;
};
