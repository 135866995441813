import { useQueuedMutation } from '~shared/hooks';

import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery, type UseMutationOptions } from '@tanstack/react-query';

import type { UpdatePersonPayload } from '../api';
import { gatewayPersonApi } from '../api';
import type { Person } from '../person';

export const useGetPersonQuery = (
  applicationId: string,
  options: Omit<UseQueryOptions<Person, Error>, 'queryKey' | 'queryFn'>
) => {
  return useQuery({
    queryKey: ['get-person', applicationId],
    queryFn: () => gatewayPersonApi.getPerson(applicationId),
    ...options,
  });
};

export const useUpdatePersonMutation = (
  options?: UseMutationOptions<any, Error, UpdatePersonPayload>
) => {
  return useQueuedMutation({
    mutationKey: ['update-person'],
    mutationFn: async (payload: UpdatePersonPayload) =>
      gatewayPersonApi.updatePerson(payload),
    ...options,
  });
};
