import { Controller, useForm } from 'react-hook-form';

import { combineValidators } from '~shared/lib/combine-validators';
import { ScreenTitle } from '~shared/ui/screen-title';
import { validators } from '~shared/validators';

import { LoadingButton } from '@breeze-platform-ui/button';
import { InputPhone } from '@breeze/rhf-adapters';

export type FormValues = { phone: string };

type Props = {
  onSubmit: (phoneNumber: string, promoterCode?: string) => void;
  isLoading?: boolean;
  initialPhone?: string;
};

export const ClientPhone: React.FC<Props> = ({
  onSubmit,
  isLoading,
  initialPhone,
}) => {
  const { control, handleSubmit } = useForm<FormValues>({
    mode: 'all',
    defaultValues: { phone: initialPhone },
  });

  const handleFormSubmit = ({ phone }: FormValues) => {
    onSubmit(phone);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="mb-5">
        <ScreenTitle title="Enter your mobile number to&nbsp;start a&nbsp;new application " />
      </div>
      <Controller
        name="phone"
        control={control}
        rules={{
          required: 'Mobile number',
          validate: combineValidators(
            validators.phoneLength(),
            validators.mobilePhoneFormat()
          ),
        }}
        render={(fieldProps) => (
          <InputPhone label="Mobile number" {...fieldProps} />
        )}
      />

      <div>
        <LoadingButton loading={isLoading} type="submit" size="l" wide>
          Get a code
        </LoadingButton>
      </div>
    </form>
  );
};
