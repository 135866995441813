import type { AbstractOption } from '~shared/types/abstract-option';

import { hasOwnBusinessFn, isSelfEmployedFn } from './employment-predicates';
import type { EmploymentStatusValue } from './employment-status-options';
import { referenceOptionsBusiness } from './reference-options-business';
import { referenceOptionsEmployee } from './reference-options-employee';
import { referenceOptionsSelfEmployed } from './reference-options-self-employed';

export const getReferenceTypeOptions = (
  status: EmploymentStatusValue
): AbstractOption[] => {
  if (hasOwnBusinessFn(status)) {
    return referenceOptionsBusiness;
  }

  if (isSelfEmployedFn(status)) {
    return referenceOptionsSelfEmployed;
  }

  return referenceOptionsEmployee;
};
