import { Button } from '~shared/ui/button';

import warningImg from './warning.svg';

export const AuthWarning: React.FC<{
  phone: string;
  onGetCode: () => void;
}> = ({ phone, onGetCode }) => {
  const maskedPhoneNumber = `${phone.slice(
    0,
    3
  )}\u00A0XXX\u00A0XXX\u00A0${phone.slice(9)}`;

  return (
    <div className="flex flex-col items-center gap-6 pb-4">
      <img src={warningImg} width="100" height="100" alt="" />
      <div className="flex flex-col items-center gap-4">
        <h1 className="text-center text-2xl">Confirm your mobile number</h1>
        <p className="text-center text-base">
          Looks like you were gone for some time. To&nbsp;continue
          filling&nbsp;out the&nbsp;form, enter the&nbsp;confirmation code we’ll
          send to&nbsp;{maskedPhoneNumber}
        </p>
      </div>
      <Button theme="primary" size="m" onClick={onGetCode}>
        Get a code
      </Button>
    </div>
  );
};
