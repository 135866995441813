import type { PropsWithChildren } from 'react';

import styles from './island.module.css';

export const Island: React.FC<
  PropsWithChildren & Pick<React.HTMLAttributes<HTMLDivElement>, 'style'>
> = ({ children, style }) => {
  return (
    <div className={styles.container} style={style}>
      {children}
    </div>
  );
};
