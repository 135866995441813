import { phMoneyProps } from '~shared/constants/ph-money-props';
import { formatPrice } from '~shared/lib/format-price';

export function getRangeMessage(min: number, max: number) {
  const formattedMin = formatPrice(min, phMoneyProps);
  const formattedMax = formatPrice(max, phMoneyProps);

  if (min === max) {
    return `Must be ${formattedMin}`;
  }

  return `Must be between ${formattedMin} 
      and ${formattedMax}`;
}
