export const COMPANY_NAME_FIELD_NAME = 'Company name';
export const HAVE_EMPLOYEES_FIELD_NAME = 'Do you have any employees?';
export const REFERENCE_FIELD_NAME = 'Reference’s relationship to you';
export const REFERENCE_PHONE_FIELD_NAME = 'Reference’s mobile number';
export const REFERENCE_NAME_FIELD_NAME = 'Name of the reference';
export const HAS_COMPANY_ADDRESS_FIELD_NAME = 'I have an exact work address';
export const COUNTRY_FIELD_NAME = 'country';
export const REGION_FIELD_NAME = 'region';
export const POSTAL_FIELD_NAME = 'postalCode';
export const PROVINCE_FIELD_NAME = 'Province';
export const CITY_MUNICIPALITY_FIELD_NAME = 'City/Municipality';
export const BARANGAY_FIELD_NAME = 'Barangay';
export const STREET_HOUSE_FIELD_NAME = 'Building number, Street name';
export const UNIT_FLOOR_FIELD_NAME = 'Floor/Office (optional)';

export const EMPLOYMENT_STATUS_FIELD_NAME = 'Type of employment';
export const NATURE_OF_WORK_FIELD_NAME = 'Industry';
export const NATURE_OF_WORK_OTHER_FIELD_NAME = 'Industry name';
export const WORK_EXPIRIENCE_FIELD_NAME = 'Work experience';
export const UNEMPLOYED_REASON_OTHER_FIELD_NAME = 'Other reason';
