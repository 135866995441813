import React from 'react';
import type { FieldErrors } from 'react-hook-form';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { useFormInitialize } from '~shared/hooks';
import { combineValidators } from '~shared/lib/combine-validators';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';
import { SectionTitle } from '~shared/ui/section-title';
import { validators } from '~shared/validators';

import { Input, Select } from '@breeze/rhf-adapters';

import { JobDetails } from './job-details';
import { UnemployedReason } from './unemployed-reason';

import employmentImage from '../../../images/employment.webp';
import { EMPLOYMENT_STATUS_FIELD_NAME } from '../../constants/field-names';
import type {
  EmploymentStatusValue,
  NatureOfWorkValue,
  UnemployedReasonValue,
  WorkExperienceValue,
} from '../../lib';
import { employmentStatusOptions } from '../../lib/employment-status-options';

export interface FormValues {
  employmentStatus: EmploymentStatusValue;
  jobTitle?: string;
  natureOfWork?: NatureOfWorkValue;
  natureOfWorkOther?: string;
  workExperience?: WorkExperienceValue;
  unemployedReason?: UnemployedReasonValue;
  unemployedReasonOther?: string;
  companyName?: string;
}

type Props = {
  initialValue?: Partial<FormValues>;
  onPrev: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onSubmit: (values: FormValues) => void;
  onFieldCompleted?: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
};

export const EmploymentDetails: React.FC<Props> = ({
  initialValue = {},
  onFieldCompleted,
  onPrev,
  onSubmit,
}) => {
  const methods = useForm<FormValues>({
    mode: 'all',
    defaultValues: initialValue,
    shouldUnregister: true,
  });
  const { control, handleSubmit, getValues, watch } = methods;
  const [employmentStatus] = watch(['employmentStatus']);

  useFormInitialize(methods, initialValue);

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };
  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onPrev(getValues(), actualErrors);
  };

  const handleFormSubmit = (values: FormValues) => {
    onSubmit(values);
  };

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(handleFormSubmit)}>
      <Screen
        header={<HeaderWithLogo />}
        footer={<FormFooter onClickPrev={handleClickButtonPrev} />}
      >
        <div className="mb-5">
          <ScreenTitle
            title="What do you do for a&nbsp;living?"
            subtitle="Tell us about your main source of&nbsp;income"
            icon={<img src={employmentImage} width={56} height={56} alt="" />}
          />
        </div>
        <FormProvider {...methods}>
          <Controller
            name="employmentStatus"
            control={control}
            rules={{
              required: 'Select one option',
            }}
            render={(fieldProps) => (
              <Select
                label={EMPLOYMENT_STATUS_FIELD_NAME}
                options={employmentStatusOptions}
                {...fieldProps}
              />
            )}
          />

          {employmentStatus === 'UNEMPLOYED' ? (
            <UnemployedReason />
          ) : (
            <JobDetails />
          )}

          {employmentStatus === 'OFW' && (
            <div className="flex flex-col items-stretch gap-5">
              <SectionTitle title="Company details" />
              <Controller
                name="companyName"
                control={control}
                rules={{
                  validate: combineValidators(
                    validators.maxLength({ maxLength: 150 }),
                    validators.latinOnly()
                  ),
                }}
                render={(fieldProps) => (
                  <Input label="Company name (if any)" {...fieldProps} />
                )}
              />
            </div>
          )}
        </FormProvider>
      </Screen>
    </form>
  );
};
