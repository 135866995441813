type Props = {
  image: React.ReactNode;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  footer?: React.ReactNode;
};

export const Result: React.FC<Props> = ({ image, title, subtitle, footer }) => {
  return (
    <div className="flex flex-col items-center gap-10">
      {typeof image === 'string' ? (
        <img src={image} alt="" width="150" height="150" />
      ) : (
        image
      )}
      <div className="flex flex-col">
        <h1 className="mb-2 text-center text-2xl font-bold">{title}</h1>
        <p className="text-center text-m text-secondary-light">{subtitle}</p>
      </div>
      {footer}
    </div>
  );
};
