import type { RadioOption } from '~shared/types/radio-option';

const options = [
  {
    value: 'Retired private employee',
    label: 'Retired private employee',
  },
  {
    value: 'Retired government employee',
    label: 'Retired government employee',
  },
  {
    value: 'Supported by husband/wife',
    label: 'Supported by husband/wife',
  },
  {
    value: 'Supported by relatives/others',
    label: 'Supported by relatives/others',
  },
  {
    value: 'Looking for a job',
    label: 'Looking for a job',
  },
  {
    value: 'Other',
    label: 'Other',
  },
] as const;

export type UnemployedReasonValue = (typeof options)[number]['value'];
export const unemployedReasonOptions =
  options as unknown as RadioOption<UnemployedReasonValue>[];
