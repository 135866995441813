import { phMoneyProps } from '~shared/constants/ph-money-props';
import { plural } from '~shared/lib/plural';

import { Col } from '@breeze-platform-ui/layout';
import Money from '@breeze-platform-ui/money';

import { calculateMonthlyPayment } from './loan-math';

import type { ProductParams } from '../product';

const loanMoneyProps = { ...phMoneyProps, precision: 2 };

export function getInstallmentPeriodOptions(
  {
    interestRate,
    originationFeeRate,
    originationFeeFlat,
    termsInMonths,
  }: ProductParams,
  loanAmount: number
) {
  return termsInMonths.map((term) => {
    const monthlyPayment = calculateMonthlyPayment(
      loanAmount,
      +term,
      interestRate,
      originationFeeRate,
      originationFeeFlat
    );

    return {
      value: `${term}`,
      children: (
        <Col alignCross="stretch" grow={1}>
          <p className="text-base font-bold text-primary">
            <Money {...loanMoneyProps} value={monthlyPayment} />
          </p>
          <p className="text-sm text-primary">
            x {term} {plural(term, 'month', 'months')}
          </p>
        </Col>
      ),
    };
  });
}
