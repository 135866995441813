import { formatAdditionalDocument } from '~widgets/application-form/additional-document';
import { formatAdditionalPersonalDetails } from '~widgets/application-form/additional-personal-details';
import { formatAlternativeContacts } from '~widgets/application-form/alternative-contacts';
import { formatEmploymentDetails } from '~widgets/application-form/employment';
import { formatAdditionalEmploymentDetails } from '~widgets/application-form/employment';
import { formatFinances } from '~widgets/application-form/finances';
import {
  formatIdDocumentNumber,
  formatIdDocumentType,
} from '~widgets/application-form/id-document';
import { formatLoanCalc } from '~widgets/application-form/loan-calc';
import { formatPermanentAddress } from '~widgets/application-form/permanent-address';
import { formatPersonalDetails } from '~widgets/application-form/personal-details';
import { formatResidentialAddress } from '~widgets/application-form/residential-address';

import type { Application } from '~entities/application';
import type { ApplicationFormatter } from '~entities/application/lib/formatter';
import type { Person, PersonFormatter } from '~entities/person';

import { getValidValues } from '~shared/lib/get-valid-values';

import isEmpty from '@tinkoff/utils/is/empty';

import type { FormErrors } from './form-values';
import type {
  ApplicationStepName,
  PersonStepName,
  StepName,
} from './step-names';
import { applicationStepNames, personStepNames } from './step-names';

export const personScreenFormatters: Record<
  PersonStepName,
  PersonFormatter<any>
> = {
  [personStepNames.personalDetails]: formatPersonalDetails,
  [personStepNames.additionalPersonalDetails]: formatAdditionalPersonalDetails,
  [personStepNames.residentialAddress]: formatResidentialAddress,
  [personStepNames.permanentAddress]: formatPermanentAddress,
  [personStepNames.employmentDetails]: formatEmploymentDetails,
  [personStepNames.finances]: formatFinances,
  [personStepNames.additionalEmploymentDetails]:
    formatAdditionalEmploymentDetails,
  [personStepNames.alternativeContacts]: formatAlternativeContacts,
  [personStepNames.idDocumentType]: formatIdDocumentType,
  [personStepNames.otherIdDocumentNumber]: formatIdDocumentNumber,
  [personStepNames.mainIdDocumentNumber]: formatIdDocumentNumber,
  [personStepNames.additionalDocument]: formatAdditionalDocument,
};

export const applicationScreenFormatters: Record<
  ApplicationStepName,
  ApplicationFormatter<any>
> = {
  [applicationStepNames.loanDetails]: formatLoanCalc,
};

type ScreensValues = Record<StepName, any>;

export const formatPersonScreenValues = (
  names: PersonStepName[],
  values: Partial<ScreensValues>,
  errors: Partial<FormErrors>
): Partial<Person> => {
  let person = {} as Person;

  for (const screenName of names) {
    const screenValue = values[screenName] ?? {};
    const validScreenValue = getValidValues(
      screenValue,
      errors[screenName] || ({} as any)
    );

    person = personScreenFormatters[screenName](
      person,
      isEmpty(validScreenValue) ? {} : validScreenValue
    );
  }

  return person;
};

export const formatApplicationScreenValues = (
  names: ApplicationStepName[],
  values: Partial<ScreensValues>,
  errors: Partial<FormErrors>
): Partial<Application> => {
  let application = {} as Partial<Application>;

  for (const screenName of names) {
    const screenValue = values[screenName];
    const validScreenValue = getValidValues(
      screenValue,
      errors[screenName] ?? ({} as any)
    );

    application = applicationScreenFormatters[screenName](
      application,
      isEmpty(validScreenValue) ? {} : validScreenValue
    );
  }

  return application;
};
