export class HttpError extends Error {
  status: number;
  name = 'HttpError' as const;
  code?: string;
  body?: any;

  constructor(options: { message: string; status: number; code?: string }) {
    const { message, status, code, ...rest } = options;

    super(message);
    this.status = status;
    this.code = code;
    this.body = rest;
  }
}

export class AbortError extends Error {
  // Compatible with browser native Abort Error
  name = 'AbortError' as const;

  constructor(options: { message: string }) {
    super(options.message);
  }
}

export class TimeoutError extends Error {
  // Compatible with browser native Abort Error
  name = 'TimeoutError' as const;

  constructor(options: { message: string }) {
    super(options.message);
  }
}
