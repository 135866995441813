import type { PropsWithChildren } from 'react';
import React from 'react';

import { ScrollIntoView } from '~shared/ui/scroll-into-view';

import { useAtomValue } from 'jotai';
import { isAuthorisedAtom } from '~demo/entities/auth';

import { AuthByPhone } from '../auth-by-phone/auth-by-phone';

type Props = {
  phoneNumber?: string;
  skipCheck?: boolean;
  onLogin?: (phone?: string) => void;
  onCancelLogin: () => void;
};

export const AuthGuard: React.FC<PropsWithChildren & Props> = React.memo(
  ({ children, skipCheck, onLogin, onCancelLogin }) => {
    const isAuthorised = useAtomValue(isAuthorisedAtom);

    if (skipCheck) {
      return children;
    }

    if (!isAuthorised) {
      return (
        <ScrollIntoView>
          <AuthByPhone onLogin={onLogin} onCancel={onCancelLogin} />)
        </ScrollIntoView>
      );
    }

    return children;
  }
);
