import type { Suggestion } from '~shared/types/suggestion';

import omit from '@tinkoff/utils/object/omit';

import { useQuery } from '@tanstack/react-query';

import { type Address } from '../address';
import type {
  AddressSuggestionParams,
  SuggestionField,
} from '../api/address-suggestions-api';
import { gatewayAddressSuggestionApi } from '../api/gateway-address-suggestions-api';
import { getChildFields } from '../lib/get-child-fields';

export interface SuggestionQueryParams {
  name: SuggestionField;
  params: AddressSuggestionParams;
}

export interface ExtendedAddressSuggestionOption extends Suggestion {
  origin: Address;
}

export interface SuggestionsResult {
  shouldResetChildFields: boolean;
  options: ExtendedAddressSuggestionOption[];
}

export const useGetSuggestionsOptionsQuery = ({
  params,
  name,
}: SuggestionQueryParams) => {
  return useQuery({
    queryKey: ['address-suggestion-options', params, name],
    queryFn: async (): Promise<SuggestionsResult> => {
      const addressFields: SuggestionField[] = [
        'province',
        'cityOrMunicipality',
        'barangay',
      ];
      const childFields = getChildFields(
        name,
        addressFields
      ) as SuggestionField[];

      let suggestions: Address[] = [];
      let shouldResetChildFields = false;

      const requestParams = {
        resultSize: 100,
        ...params,
      };
      try {
        suggestions = await gatewayAddressSuggestionApi.getAddressSuggestion(
          requestParams
        );

        if (suggestions.length === 0 && childFields.length > 0) {
          suggestions = await gatewayAddressSuggestionApi.getAddressSuggestion(
            omit(childFields, requestParams)
          );
          shouldResetChildFields = true;
        }
      } catch (error) {
        // do nothing
      }
      const suggestionOptions = suggestions.reduce<
        ExtendedAddressSuggestionOption[]
      >((acc, suggestion: Address) => {
        const text = suggestion[name];
        if (text) {
          acc.push({ title: text, value: text, origin: suggestion });
        }
        return acc;
      }, []);

      return { options: suggestionOptions, shouldResetChildFields };
    },
  });
};
