import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const userPhoneAtom = atomWithStorage<string | null>(
  'user_phone',
  null,
  undefined,
  { getOnInit: true }
);
export const isAuthorisedAtom = atom<boolean>(false);
export const isFirstAuthAtom = atom<boolean>(true);

export const restoreSessionSuccessAtom = atom(null, (_get, set) => {
  set(isAuthorisedAtom, true);
  set(isFirstAuthAtom, false);
});

export const loginAtom = atom(null, (_get, set, phone: string) => {
  set(userPhoneAtom, phone);
  set(isAuthorisedAtom, true);
  set(isFirstAuthAtom, false);
});

export const logoutAtom = atom(null, (_get, set) => {
  set(isAuthorisedAtom, false);
});
