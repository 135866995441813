import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { gatewayOrdersApi } from '../api/gateway-orders-api';
import type { Order } from '../order';

export const useGetOrderByIdQuery = (
  id: string | undefined,
  options: Omit<UseQueryOptions<Order, Error>, 'queryKey' | 'queryFn'>
) =>
  useQuery({
    queryKey: ['order-item', id],
    queryFn: async () => {
      if (!id) {
        throw new Error('ORDER_NOT_FOUND');
      }

      return gatewayOrdersApi.getOrder(id);
    },
    ...options,
  });
