import type {
  FieldErrors,
  FieldValues,
  Path,
  UseFormReturn,
} from 'react-hook-form';

type FieldsState<T extends FieldValues> = FieldErrors<T>;
export function getFieldsErrors<T extends FieldValues>(
  useForm: UseFormReturn<T>
): FieldsState<T> {
  const { getFieldState, getValues } = useForm;

  const fields = Object.keys(getValues()) as Path<T>[];
  const fieldsState = fields.reduce(
    (state, field) => ({ ...state, [field]: getFieldState(field).error }),
    {} as FieldsState<T>
  );

  return fieldsState;
}
