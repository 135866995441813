import { ageRange } from './age-range/age-range';
import { email } from './email/email';
import { fileRequired } from './file-required/file-required';
import { filipinoAddress } from './filipino-address/filipino-address';
import { filipinoCompany } from './filipino-company/filipino-company';
import { filipinoGeo } from './filipino-geo/filipino-geo';
import { filipinoName } from './filipino-name/filipino-name';
import { isDate } from './is-date/is-date';
import { latinOnly } from './latin-only/latin-only';
import { latinText } from './latin-text/latin-text';
import { maxLength } from './max-length/max-length';
import { minLength } from './min-length/min-length';
import { mobilePhoneFormatPh } from './mobile-phone-format-ph/mobile-phone-format-ph';
import { phoneFormatPh } from './phone-format-ph/phone-format-ph';
import { phoneLength } from './phone-length/phone-length';
import { required } from './required/required';
import { suggestOption } from './suggest-option/suggest-option';

export const validators = {
  isDate,
  ageRange,
  maxLength,
  minLength,
  latinOnly,
  latinText,
  phoneLength,
  filipinoGeo,
  filipinoName,
  filipinoCompany,
  filipinoAddress,
  phoneFormat: phoneFormatPh,
  mobilePhoneFormat: mobilePhoneFormatPh,
  email,
  suggestOption,
  fileRequired,
  required,
};
