import type { PropsWithChildren } from 'react';
import React from 'react';

interface Props extends PropsWithChildren {
  current?: string;
}

export const Stepper: React.FC<Props> = React.memo((props) => {
  const { children, current } = props;
  const arrayChildren = React.Children.toArray(children);

  if (arrayChildren.length > 1) {
    return (
      <>
        {arrayChildren.find((step) => {
          return (step as any)?.props?.name === current;
        })}
      </>
    );
  }

  return children;
});
