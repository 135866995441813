import { useState } from 'react';

import { isAuthError } from '~shared/errors';
import { plural } from '~shared/lib/plural';

import { ButtonLink } from '@breeze-platform-ui/button';
import Text from '@breeze-platform-ui/text';

interface Props {
  timer?: number;
  disabled?: boolean;
  onResend: () => Promise<void>;
  onResendLimitError: () => void;
}

export const ResendButton: React.FC<Props> = (props) => {
  const { disabled, onResend, onResendLimitError, timer = 0 } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const resendDisabled = loading || disabled;

  const handleResend = async () => {
    setLoading(true);
    setError(undefined);
    try {
      await onResend();
      setLoading(false);
    } catch (err) {
      if (err && isAuthError(err as Error)) {
        onResendLimitError();
      }
      setError(err as Error);
      setLoading(false);
    }
  };

  return (
    <>
      {timer ? (
        <Text color="rgba(0, 0, 0, 0.4)" size={13}>
          Request a&nbsp;new code in&nbsp;{timer}{' '}
          {plural(timer, 'second', 'seconds')}
        </Text>
      ) : (
        <ButtonLink
          size="s"
          disableHorizontalPadding
          disableVerticalPadding
          disabled={resendDisabled}
          onClick={handleResend}
        >
          Request a&nbsp;new code
        </ButtonLink>
      )}
      {!!error && (
        <Text color="#e31c1c" size={13}>
          Error receiving the&nbsp;code
        </Text>
      )}
    </>
  );
};
