import { useState } from 'react';

import {
  AuthByOtp,
  AuthorizationError,
  lastOtpRequestAtom,
} from '~features/otp';

import { loginAtom, useInitiateMutation } from '~entities/auth';

import { HeaderWithClose } from '~shared/header-with-close/header-with-close';
import { nonNullableValue } from '~shared/types/non-nullable-value';
import { Screen } from '~shared/ui/screen';
import { ScrollIntoView } from '~shared/ui/scroll-into-view';
import { Step, Stepper } from '~shared/ui/stepper';

import { useSetAtom } from 'jotai';

import { ClientPhone } from './ui/client-phone/client-phone';

const stepsNames = {
  clientPhone: 'clientPhone',
  smsConfirmation: 'smsConfirmation',
};

type Props = {
  initialPhone?: string;
  onLogin?: (phone: string) => void;
  onCancel?: () => void;
};

export const AuthByPhone: React.FC<Props> = ({
  initialPhone,
  onLogin,
  onCancel,
}) => {
  const setLastOtpRequest = useSetAtom(lastOtpRequestAtom);
  const login = useSetAtom(loginAtom);

  const [phoneNumber, setPhoneNumber] = useState(initialPhone ?? '');
  const [stepName, setStepName] = useState(stepsNames.clientPhone);
  const [isAuthError, setIsAuthError] = useState<boolean>(false);

  const { mutate: initiate, isPending: isInitiateInProgress } =
    useInitiateMutation({
      onSuccess(_d, params) {
        setIsAuthError(false);
        setLastOtpRequest({
          phone: params.phoneNumber,
          time: new Date().getTime(),
        });
        setPhoneNumber(params.phoneNumber);
        setStepName('smsConfirmation');
      },
      onError() {
        setIsAuthError(true);
      },
    });

  const handleLoginSuccess = () => {
    login(phoneNumber);
    onLogin?.(phoneNumber);
  };

  const handleClientInfoSubmit = async (phone: string) => {
    setIsAuthError(false);
    setPhoneNumber(phone);
    initiate({ phoneNumber: phone });
  };

  if (isAuthError) {
    return <AuthorizationError />;
  }

  const handleCloseAuth =
    stepName === stepsNames.smsConfirmation
      ? () => setStepName(stepsNames.clientPhone)
      : onCancel;
  const headerText =
    stepName === stepsNames.smsConfirmation ? 'Enter the code' : undefined;

  return (
    <Screen
      header={<HeaderWithClose onClose={handleCloseAuth} text={headerText} />}
    >
      <Stepper current={stepName}>
        <Step name={stepsNames.clientPhone}>
          <ScrollIntoView>
            <ClientPhone
              onSubmit={handleClientInfoSubmit}
              isLoading={isInitiateInProgress}
              initialPhone={initialPhone}
            />
          </ScrollIntoView>
        </Step>
        <Step name={stepsNames.smsConfirmation}>
          <ScrollIntoView>
            <AuthByOtp
              phoneNumber={nonNullableValue(phoneNumber)}
              onSuccess={handleLoginSuccess}
              onAuthError={() => setIsAuthError(true)}
            />
          </ScrollIntoView>
        </Step>
      </Stepper>
    </Screen>
  );
};
