import React, { useState } from 'react';
import type { FieldErrors } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

import { environment } from '~app/environment';

import {
  shouldAskForAdditionalId,
  type IdDocumentType,
} from '~entities/person';

import { useFormInitialize } from '~shared/hooks';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

import { CheckboxLabeled } from '@breeze-platform-ui/label';
import { MaskedInput } from '@breeze/rhf-adapters';

import documentImage from '../../images/document.webp';
import type {
  MainIdDocumentTypeValue,
  OtherIdDocumentTypeValue,
} from '../../model/document-type';
import { REQUIRED_NUMBER_ERROR } from '../constants';
import { idDocumentMetaMap } from '../lib';

export type FormValues = {
  idType: IdDocumentType;
  idNumber?: string;
};

type Props = Readonly<{
  initialValue?: Partial<FormValues>;
  currentIdType: MainIdDocumentTypeValue | OtherIdDocumentTypeValue;
  onPrev: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onSubmit: (values: FormValues, skipValidation?: boolean) => void;
  onFieldCompleted?: (
    value: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
}>;

export const IdDocumentNumber: React.FC<Props> = ({
  onSubmit,
  initialValue,
  currentIdType = 'UMID',
  onPrev,
  onFieldCompleted,
}) => {
  // we need to reset id document data if document type has been changed
  const realInitialValue =
    initialValue?.idType === currentIdType
      ? initialValue
      : { idType: currentIdType, idNumber: '' };
  const methods = useForm<FormValues>({
    defaultValues: realInitialValue,
    mode: 'all',
  });
  const { control, getValues, handleSubmit, register } = methods;

  useFormInitialize(methods, realInitialValue);

  const [skipValidation, setSkipValidation] = useState<boolean>();

  const idDocumentMeta = idDocumentMetaMap[currentIdType];

  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onPrev(getValues(), actualErrors);
  };

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };

  const canSkipServerValidation = environment.CAN_SKIP_VALIDATION;
  const isLastScreen = !shouldAskForAdditionalId(currentIdType);

  return (
    <form
      onBlur={handleBlur}
      onSubmit={handleSubmit((values) => onSubmit(values, skipValidation))}
    >
      <Screen
        header={<HeaderWithLogo />}
        footer={
          <FormFooter
            onClickPrev={handleClickButtonPrev}
            isLastScreen={isLastScreen}
          />
        }
      >
        <div className="mb-5">
          <ScreenTitle
            title="Main identity document"
            icon={<img src={documentImage} width={56} height={56} alt="" />}
          />
        </div>

        <input {...register('idType')} hidden />
        <Controller
          control={control}
          name="idNumber"
          shouldUnregister
          rules={{
            required: REQUIRED_NUMBER_ERROR,
            ...idDocumentMeta.validators,
          }}
          render={(fieldProps) => (
            <MaskedInput
              {...fieldProps}
              field={{
                ...fieldProps.field,
                // TODO: check why there can be null value after changing type
                value: fieldProps.field.value || '',
                onChange: (event, params) => {
                  fieldProps.field.onChange(
                    idDocumentMeta.transform(event, params)
                  );
                },
              }}
              partialPlaceholder
              label={idDocumentMeta.label}
              mask={idDocumentMeta.mask.value}
              maskTransitions={idDocumentMeta.mask.transitions}
              placeholder={idDocumentMeta.placeholder}
            />
          )}
        />
        {isLastScreen && canSkipServerValidation && (
          <CheckboxLabeled
            checked={skipValidation}
            onChange={(_e, parameters) => {
              setSkipValidation(parameters.checked);
            }}
          >
            Move the application to the status APPROVED (dev envs only)
          </CheckboxLabeled>
        )}
      </Screen>
    </form>
  );
};
