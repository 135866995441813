import type { Application } from '../application';

export const isOnboarding = ({
  applicationStatus,
  formFillingStatus,
}: Application) =>
  applicationStatus === 'NEW' && formFillingStatus === 'IN_PROGRESS';

export const isPreapproved = (applicationDetails: Application) =>
  applicationDetails.qualified;

export const isRejected = ({ applicationStatus }: Application) =>
  applicationStatus === 'REJECTED';

export const isWaitingForDecision = (application: Application) =>
  !isOnboarding(application) &&
  !isRejected(application) &&
  !isPreapproved(application);
