import throttle from '@tinkoff/utils/function/throttle';

const USER_INTERACT_EVENTS = [
  'click',
  'mousemove',
  'scroll',
  'touchstart',
  'touchend',
  'touchmove',
  'touchcancel',
];

export function addUserInteractionsListener(
  callback: Function,
  throttleTime: number
): Function {
  const cb = throttle(throttleTime, () => callback());
  const subscriptions: Function[] = [];

  USER_INTERACT_EVENTS.forEach((event) => {
    window.addEventListener(event, cb, { passive: true });
    subscriptions.push(() => {
      window.removeEventListener(event, cb);
    });
  });

  return () => {
    subscriptions.forEach((unsubscribe) => unsubscribe());
  };
}
