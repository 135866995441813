import { Controller, useFormContext } from 'react-hook-form';

import { combineValidators } from '~shared/lib/combine-validators';
import { validators } from '~shared/validators';

import { Input, Select } from '@breeze/rhf-adapters';

import type { FormValues } from './employment-details';

import type { EmploymentStatusValue } from '../../lib';
import {
  natureOfWorkOptions,
  jobTitleLabels,
  jobTitleMessages,
  workExperienceOptions,
} from '../../lib';

type EmployedFormValues = Omit<FormValues, 'employmentStatus'> & {
  employmentStatus: Exclude<EmploymentStatusValue, 'UNEMPLOYED'>;
};

export const JobDetails: React.FC = () => {
  const { control, watch } = useFormContext<EmployedFormValues>();
  const [employmentStatus = 'FULL_TIME', natureOfWork] = watch([
    'employmentStatus',
    'natureOfWork',
  ]);

  return (
    <>
      <Controller
        name="jobTitle"
        control={control}
        rules={{
          validate: combineValidators(
            validators.required({
              text: jobTitleMessages[employmentStatus],
            }),
            validators.maxLength({ maxLength: 300 }),
            validators.filipinoName()
          ),
        }}
        render={(fieldProps) => (
          <Input label={jobTitleLabels[employmentStatus]} {...fieldProps} />
        )}
      />
      <Controller
        name="natureOfWork"
        control={control}
        rules={{
          required: 'Select one option',
        }}
        render={(fieldProps) => (
          <Select
            label="Industry"
            options={natureOfWorkOptions}
            {...fieldProps}
          />
        )}
      />
      {natureOfWork === 'Other' && (
        <Controller
          name="natureOfWorkOther"
          control={control}
          rules={{
            validate: combineValidators(
              validators.required({
                text: 'Enter your industry name',
              }),
              validators.maxLength({ maxLength: 150 }),
              validators.latinOnly()
            ),
          }}
          render={(fieldProps) => (
            <Input label="Industry name" {...fieldProps} />
          )}
        />
      )}
      <Controller
        name="workExperience"
        control={control}
        rules={{
          required: 'Select one option',
        }}
        render={(fieldProps) => (
          <Select
            label="You’ve worked in this industry for"
            options={workExperienceOptions}
            {...fieldProps}
          />
        )}
      />
    </>
  );
};
