import type { ComponentProps } from 'react';
import React from 'react';

import type { Box } from '@breeze-platform-ui/layout';

type Props = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
} & Omit<ComponentProps<typeof Box>, 'title' | 'subtitle'>;

export const SectionTitle: React.FC<Props> = ({ title, subtitle }) => {
  return (
    <div>
      {title && <h2 className="mb-1 text-xl font-bold">{title}</h2>}
      {subtitle && <p className="text-s">{subtitle}</p>}
    </div>
  );
};
