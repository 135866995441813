import { Button } from '~shared/ui/button';

interface Props {
  nextButtonDisabled?: boolean;
  onClickPrev?: () => void;
  isLastScreen?: boolean;
}
export const FormFooter = ({
  nextButtonDisabled,
  onClickPrev,
  isLastScreen,
}: Props) => {
  return (
    <div className="flex w-full items-center justify-between gap-3">
      {onClickPrev && (
        <Button
          wide
          size="m"
          type="button"
          theme="secondary"
          disableHorizontalPadding
          onClick={onClickPrev}
        >
          Back
        </Button>
      )}
      <Button wide size="m" type="submit" disabled={nextButtonDisabled}>
        {isLastScreen ? 'Submit' : 'Next'}
      </Button>
    </div>
  );
};
