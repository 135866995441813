import React, { useEffect, useMemo } from 'react';
import type { Message } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';

import {
  getMinDownPayment,
  loanMath,
  getMaxDownPayment,
  getRangeMessage,
} from '~entities/product';
import type { ProductParams } from '~entities/product';
import { getInstallmentPeriodOptions } from '~entities/product';

import { phMoneyProps } from '~shared/constants/ph-money-props';
import { useOnMountEffect } from '~shared/hooks';
import { Button } from '~shared/ui/button';
import { validators } from '~shared/validators';

import { FormFieldset } from '@breeze-platform-ui/form';
import { RadioGroup, InputMoney } from '@breeze/rhf-adapters';

import {
  DOWNPAYMENT_LABEL,
  DOWNPAYMENT_REQUIRED_ERROR,
  PERIOD_REQUIRED_ERROR,
} from '../constants';
import { validateDownPaymentRange } from '../lib';

export type FormValues = {
  downPayment: string;
  period: string;
};

type Props = {
  totalPrice: number;
  loanParams: ProductParams;
};

export const SetupLoan = React.memo(({ totalPrice, loanParams }: Props) => {
  const { control, watch, formState, trigger, setValue, getValues } =
    useFormContext<FormValues>();
  const {
    minLoanAmount,
    maxLoanAmount,
    minDownPaymentPercent,
    maxDownPaymentPercent,
  } = loanParams;

  const downPayment = watch('downPayment');
  const loanAmount = downPayment
    ? loanMath.calculateLoanAmount(totalPrice, +downPayment)
    : 0;

  const initialDownPayment = getMinDownPayment(totalPrice, {
    maxLoanAmount,
    minDownPaymentPercent,
    maxDownPaymentPercent,
  });

  const options = useMemo(
    () => getInstallmentPeriodOptions(loanParams, loanAmount || 0),
    [loanParams, loanAmount]
  );

  useOnMountEffect(() => {
    if (!getValues('downPayment')) {
      setValue('downPayment', `${initialDownPayment}`);
    }

    if (!getValues('period')) {
      setValue('period', options[0].value);
    }
  });

  // we need to revalidate downPayment when totalPrice changes
  useEffect(() => {
    trigger('downPayment');
  }, [totalPrice, loanParams, trigger]);

  const shouldShowDownPayment = maxDownPaymentPercent > 0;
  const downPaymentValid =
    !shouldShowDownPayment || !formState.errors.downPayment;
  const downPaymentMessage =
    shouldShowDownPayment &&
    getRangeMessage(
      getMinDownPayment(totalPrice, {
        maxLoanAmount,
        minDownPaymentPercent,
        maxDownPaymentPercent,
      }),
      getMaxDownPayment(totalPrice, {
        minLoanAmount,
        minDownPaymentPercent,
        maxDownPaymentPercent,
      })
    );

  return (
    <FormFieldset>
      {shouldShowDownPayment && (
        <Controller
          name="downPayment"
          control={control}
          rules={{
            required: DOWNPAYMENT_REQUIRED_ERROR,
            validate: (value) =>
              // react-hook-form doesn't accept ReactNode as error, but FormRow does -> have to use any
              validateDownPaymentRange(+value, totalPrice, {
                maxLoanAmount,
                minLoanAmount,
                minDownPaymentPercent,
                maxDownPaymentPercent,
              }) as Message,
          }}
          render={(props) => (
            <InputMoney
              {...props}
              {...phMoneyProps}
              cleanable
              currencyInValue
              withValidityMark={false}
              label={DOWNPAYMENT_LABEL}
              emptyValue={initialDownPayment}
              message={!props.fieldState.error && downPaymentMessage}
            />
          )}
        />
      )}
      {!Number.isNaN(loanAmount) && loanAmount > 0 && downPaymentValid && (
        <Controller
          name="period"
          control={control}
          rules={{
            validate: validators.required({ text: PERIOD_REQUIRED_ERROR }),
          }}
          render={(props) => (
            <RadioGroup
              {...props}
              qaLabel="period-radio"
              vertical
              wide
              options={options as any}
            />
          )}
        />
      )}
      <Button type="submit" size="l" wide disabled={!formState.isValid}>
        Apply for this loan
      </Button>
    </FormFieldset>
  );
});
