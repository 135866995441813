import type { PropsWithChildren } from 'react';
import type React from 'react';

interface Props extends PropsWithChildren {
  name: string;
}

export const Step: React.FC<Props> = ({ children }) => {
  return children;
};
