import { useMemo, type PropsWithChildren } from 'react';

import { logoutAtom } from '~entities/auth';

import { isAuthError, isServerError } from '~shared/errors';

import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { useSetAtom } from 'jotai';

export const AppQueryClientProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const logout = useSetAtom(logoutAtom);
  const queryClient = useMemo(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (err) => {
            if (isAuthError(err)) {
              logout();
            }
          },
        }),
        mutationCache: new MutationCache({
          onError: (err) => {
            if (isAuthError(err)) {
              logout();
            }
          },
        }),
        defaultOptions: {
          queries: {
            retry: (count: number, error: Error) => {
              if (isServerError(error)) {
                return count < 2;
              }
              return false;
            },
            refetchOnWindowFocus: false,
          },
          mutations: {
            retry: (count: number, error: Error) => {
              if (isServerError(error)) {
                return count < 2;
              }
              return false;
            },
          },
        },
      }),
    [logout]
  );

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
