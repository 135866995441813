import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { yesNoOptions } from '~shared/constants/yes-no-options';
import { combineValidators } from '~shared/lib/combine-validators';
import { SectionTitle } from '~shared/ui/section-title';
import { validators } from '~shared/validators';

import { Input, Select } from '@breeze/rhf-adapters';

import {
  COMPANY_NAME_REQUIRED_ERROR,
  SELECT_REQUIRED_ERROR,
} from '../../constants/errors';
import {
  COMPANY_NAME_FIELD_NAME,
  HAVE_EMPLOYEES_FIELD_NAME,
} from '../../constants/field-names';
import type { EmployedWithCompanyStatusValue } from '../../lib';

type Props = Readonly<{
  employmentStatus: EmployedWithCompanyStatusValue;
}>;

export const CompanyDetails: React.FC<Props> = ({ employmentStatus }) => {
  const { control } = useFormContext();
  const isBusinessOwner = employmentStatus === 'OWN_BUSINESS';
  const sectionTitle = isBusinessOwner
    ? 'Details about your business'
    : 'Where do you work?';

  return (
    <>
      <div className="mb-5">
        <SectionTitle title={sectionTitle} />
      </div>
      <Controller
        name="companyName"
        control={control}
        rules={{
          validate: combineValidators(
            validators.required({ text: COMPANY_NAME_REQUIRED_ERROR }),
            validators.maxLength({ maxLength: 150 }),
            validators.filipinoCompany()
          ),
        }}
        render={(fieldProps) => (
          <Input {...fieldProps} label={COMPANY_NAME_FIELD_NAME} />
        )}
      />
      {isBusinessOwner && (
        <Controller
          name="haveEmployees"
          control={control}
          rules={{
            required: SELECT_REQUIRED_ERROR,
          }}
          render={(fieldProps) => (
            <Select
              {...fieldProps}
              label={HAVE_EMPLOYEES_FIELD_NAME}
              options={yesNoOptions}
            />
          )}
        />
      )}
    </>
  );
};
