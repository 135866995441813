import type { OrderItem } from '~entities/order';

import { phMoneyProps } from '~shared/constants/ph-money-props';

import Money from '@breeze-platform-ui/money';

import deliveryImg from './images/delivery-stub.svg';
import orderItemImg from './images/order-item-stub.svg';

const getOrderItemImage = (item: OrderItem) => {
  if (item.imageUrl) {
    return (
      <img
        src={item.imageUrl}
        alt={item.name}
        width="40"
        height="40"
        className="h-auto shrink self-center rounded-sm"
      />
    );
  }

  return (
    <div className="box-border h-10 w-10 rounded-lg bg-[#F6F7F8] p-2">
      <img
        src={
          item.category?.toLowerCase() === 'shipping'
            ? deliveryImg
            : orderItemImg
        }
        alt="delivery"
        width="24"
        height="24"
      />
    </div>
  );
};

export const OrderItemUI: React.FC<{ item: OrderItem }> = ({ item }) => {
  const totalPrice = +item.price * item.quantity;
  return (
    <div className="flex items-start gap-4" data-qa-type="order-item">
      {getOrderItemImage(item)}
      <div className="grow">
        <h6 className="text-m font-bold">{item.name}</h6>
        <p className="text-s">
          {item.quantity > 1 && (
            <span data-qa-type="order-item-quantity">
              {item.quantity}&nbsp;×&nbsp;
            </span>
          )}
          <Money
            dataQaType="order-item-price"
            {...phMoneyProps}
            value={item.price}
            precision={2}
          />
        </p>
      </div>
      <p className="text-m font-bold">
        <Money
          dataQaType="order-items-total-price"
          {...phMoneyProps}
          value={totalPrice}
          precision={2}
        />
      </p>
    </div>
  );
};
