import type { AbstractOption } from '~shared/types/abstract-option';

const options = [
  {
    value: 'BUSINESS_PARTNER',
    title: 'Business partner',
  },
  {
    value: 'EMPLOYEE',
    title: 'Employee',
  },
  {
    value: 'CLIENT',
    title: 'Client',
  },
  {
    value: 'SUPPLIER',
    title: 'Supplier',
  },
  {
    value: 'FAMILY_MEMBER',
    title: 'Family member',
  },
  {
    value: 'OTHER',
    title: 'Other',
  },
] as const;

export type ReferenceBusinessValue = (typeof options)[number]['value'];
export const referenceOptionsBusiness = options as unknown as AbstractOption[];
