import type { PropsWithChildren } from 'react';
import React, { useEffect, useRef } from 'react';

export const ScrollIntoView: React.FC<PropsWithChildren> = ({ children }) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    elementRef.current?.scrollIntoView();
  }, []);

  return <div ref={elementRef}>{children}</div>;
};
