import { memo, useMemo } from 'react';

export const Timer: React.FC<{ seconds: number; limitInSeconds: number }> =
  memo(({ seconds, limitInSeconds }) => {
    const circleDasharray = useMemo(() => {
      const rawTimeFraction = (limitInSeconds - seconds) / limitInSeconds;
      const optimizedTimeFraction =
        rawTimeFraction - (1 / limitInSeconds) * (1 - rawTimeFraction);
      return `${(optimizedTimeFraction * 283).toFixed(0)} 283`;
    }, [seconds, limitInSeconds]);

    return (
      <div className="relative h-[156px] w-[156px]" data-qa-type="review-timer">
        <svg
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            transform: 'scaleX(-1) rotate(90deg)',
          }}
        >
          <defs>
            <linearGradient id="timer" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#4D77FF" />
              <stop offset="30%" stopColor="#729BFF" />
              <stop offset="58%" stopColor="#72AFF8" />
              <stop offset="100%" stopColor="#72E7F7" />
            </linearGradient>
          </defs>
          <g className="relative fill-none stroke-none">
            <circle
              stroke="#F6F7F8"
              cx="50"
              cy="50"
              r="45"
              strokeWidth="7"
              strokeLinecap="round"
            />
            <path
              stroke="url(#timer)"
              strokeWidth="7"
              strokeLinecap="round"
              strokeDasharray={circleDasharray}
              d="
              M 50, 50
              m -45, 0
              a 45,45 0 1,0 90,0
              a 45,45 0 1,0 -90,0
              "
            />
          </g>
        </svg>
        <h1 className="absolute left-0 top-[36px] w-full text-center text-[70px] font-normal leading-[80px]">
          {limitInSeconds - seconds}
        </h1>
      </div>
    );
  });
