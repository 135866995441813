import { useState } from 'react';

import {
  AuthByOtp,
  AuthorizationError,
  lastOtpRequestAtom,
  resetLastOtpRequestsAtom,
} from '~features/otp';

import {
  isSuccessResponse,
  loginAtom,
  useInitiateMutation,
} from '~entities/auth';

import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Loader } from '~shared/ui/loader';
import { Screen } from '~shared/ui/screen';
import { ScrollIntoView } from '~shared/ui/scroll-into-view';
import { Step, Stepper } from '~shared/ui/stepper';

import { useSetAtom } from 'jotai';

import { AuthWarning } from './ui/warning/warning';

type Props = {
  phone: string;
  onLogin?: (phone: string) => void;
};

enum StepName {
  Warning = 'WARNING',
  Confirmation = 'CONFIRMATION',
}

export const RepeatedAuth: React.FC<Props> = ({
  phone: phoneNumber,
  onLogin,
}) => {
  const login = useSetAtom(loginAtom);
  const setLastOtpRequest = useSetAtom(lastOtpRequestAtom);
  const resetLastOtpRequest = useSetAtom(resetLastOtpRequestsAtom);
  const [stepName, setStepName] = useState<StepName>(StepName.Warning);

  const handleLoginSuccess = () => {
    login(phoneNumber);
    onLogin?.(phoneNumber);
  };

  const {
    mutate: initiate,
    error: initiateError,
    isPending: isInitiateInProgress,
  } = useInitiateMutation({
    onSuccess(result, params) {
      if (isSuccessResponse(result)) {
        // user is already logged in
        handleLoginSuccess();
        resetLastOtpRequest(params.phoneNumber);
      } else {
        setLastOtpRequest({
          phone: phoneNumber,
          time: new Date().getTime(),
        });
      }
    },
  });

  const handleGetCode = () => {
    initiate({ phoneNumber });
    setStepName(StepName.Confirmation);
  };

  if (initiateError) {
    return <AuthorizationError />;
  }

  return (
    <Screen header={<HeaderWithLogo />}>
      <Stepper current={stepName}>
        <Step name={StepName.Warning}>
          <ScrollIntoView>
            <AuthWarning phone={phoneNumber} onGetCode={handleGetCode} />
          </ScrollIntoView>
        </Step>
        <Step name={StepName.Confirmation}>
          <ScrollIntoView>
            {isInitiateInProgress ? (
              <Loader />
            ) : (
              <AuthByOtp
                phoneNumber={phoneNumber}
                onSuccess={handleLoginSuccess}
              />
            )}
          </ScrollIntoView>
        </Step>
      </Stepper>
    </Screen>
  );
};
