export const RejectionTooltip = () => (
  <div className="flex flex-col gap-1">
    <p className="text-xs">
      We’re sorry you weren’t approved. Here is a list of the most common
      reasons why we couldn’t approve you for a loan.
    </p>
    <ol className="flex list-inside flex-col gap-1 p-0 text-xs">
      <li>Incorrect data</li>
      <li>Poor credit history</li>
      <li>High debt burden</li>
      <li>Inactive phone number</li>
    </ol>
    <p className="text-body-xs">
      We can’t disclose the exact reasons why a particular customer wasn’t
      approved for a loan — it’s simply not safe for us.
    </p>
    <p className="text-body-xs">
      If you have any remaining questions, you can always check our&nbsp;
      <a
        href="https://www.skyro.ph/faq#why-not-approved"
        target="_blank"
        rel="noreferrer"
        className="text-blue-500 no-underline"
      >
        FAQ Section
      </a>
    </p>
  </div>
);
