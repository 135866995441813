import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CreateApplicationError } from '~widgets/create-application-error/create-application-error';

import type { Application } from '~entities/application';
import {
  useCreateOrFindApplicationMutation,
  useSubmitApplicationMutation,
} from '~entities/application';
import { featureFlags } from '~entities/application/lib/skip-validation-flags';
import { isOnboarding } from '~entities/application/lib/status-checkers';
import { userPhoneAtom } from '~entities/auth';
import { useGetOrderByIdQuery } from '~entities/order';
import { useGetPersonQuery } from '~entities/person';

import { useOnMountEffect } from '~shared/hooks';
import errorImage from '~shared/images/error.webp';
import { nonNullableValue } from '~shared/types/non-nullable-value';
import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Loader } from '~shared/ui/loader';
import { Result } from '~shared/ui/result';
import { Screen } from '~shared/ui/screen';

import { useAtomValue, useSetAtom } from 'jotai';

import { LoanApplicationForm } from './form/application-form';

import {
  recoverApplicationScreenValues,
  recoverPersonScreenValues,
} from '../lib';
import { recoverInitialStep } from '../lib/recover-initial-step';
import {
  initializeFormAtom,
  initializeFormSuccessAtom,
} from '../model/form-atoms';

export const OrderPage: React.FC = () => {
  const navigate = useNavigate();
  const userPhone = useAtomValue(userPhoneAtom);
  const { orderId } = useParams();
  const [createOrFindError, setCreateOrFindError] = useState<Error>();

  useOnMountEffect(() => {
    window.clarity?.('set', 'order_id', orderId);
  });

  const {
    data: order,
    isLoading: isOrderLoading,
    isSuccess,
    error: getOrderError,
  } = useGetOrderByIdQuery(orderId, {
    enabled: !!orderId,
  });
  const isOrderFound = isSuccess && order.orderItems.length > 0;

  const {
    mutateAsync: createApplicationAsync,
    data: application,
    isSuccess: isApplicationCreated,
  } = useCreateOrFindApplicationMutation();
  const { mutateAsync: submitAsync } = useSubmitApplicationMutation();

  const { data: person, isSuccess: isPersonLoaded } = useGetPersonQuery(
    nonNullableValue(application?.id),
    {
      enabled: !!application?.id,
    }
  );

  const initializeForm = useSetAtom(initializeFormAtom);
  const recoverForm = useSetAtom(initializeFormSuccessAtom);

  // initialize form if session has been restored
  const handleInitialize = async (applicationData: Partial<Application>) => {
    initializeForm();

    try {
      const result = await createApplicationAsync({
        orderId: nonNullableValue(orderId),
        requestedProduct: applicationData.requestedProduct,
      });

      window.clarity?.('set', 'application_id', result.id);

      // submitted application -> show application status screen
      if (!isOnboarding(result)) {
        navigate(`/applications/${result.id}`, { replace: true });
      }
    } catch (error: any) {
      setCreateOrFindError(error);
    }
  };

  useEffect(() => {
    if (isApplicationCreated && isPersonLoaded) {
      const recoveredApplication = recoverApplicationScreenValues(application);
      const recoveredPerson = recoverPersonScreenValues(person);

      recoverForm({
        values: { ...recoveredPerson, ...recoveredApplication },
        recoveredInitialStep: recoverInitialStep(
          application.id,
          person.metaData
        ),
      });
    }
  }, [
    isApplicationCreated,
    application,
    isPersonLoaded,
    person,
    recoverForm,
    initializeForm,
  ]);

  const handleSubmit = async (skipValidation?: boolean) => {
    const { id } = nonNullableValue(application);

    await submitAsync({
      applicationId: id,
      featureFlags: (skipValidation && featureFlags) || undefined,
    });
    navigate(`/applications/${id}`);
  };

  if (isOrderLoading) {
    return (
      <Screen header={<HeaderWithLogo />}>
        <Loader centered />
      </Screen>
    );
  }

  if (getOrderError) {
    return (
      <Screen header={<HeaderWithLogo />}>
        <Result
          image={errorImage}
          title="We can't find your order"
          subtitle="Please, check the&nbsp;link"
        />
      </Screen>
    );
  }

  if (createOrFindError) {
    return (
      <Screen header={<HeaderWithLogo />}>
        <div className="mt-20">
          <CreateApplicationError
            error={createOrFindError}
            backLink={order?.failUrl}
          />
        </div>
      </Screen>
    );
  }

  if (isOrderFound) {
    return (
      <LoanApplicationForm
        applicationId={application?.id}
        order={nonNullableValue(order)}
        onInitialize={handleInitialize}
        clientPhone={userPhone ?? undefined}
        onSubmit={handleSubmit}
      />
    );
  }

  return <Screen header={<HeaderWithLogo />}>Something went wrong</Screen>;
};
