import type {
  AddressSuggestionParams,
  SuggestionField,
} from '../api/address-suggestions-api';

type Params = {
  fieldName: string;
  searchQuery?: string;
  fieldNamesMap: Record<string, SuggestionField>;
  addressValues: Record<string, string>;
};

export const getSuggestionRequestParams = ({
  fieldName,
  searchQuery,
  fieldNamesMap,
  addressValues,
}: Params): AddressSuggestionParams => {
  const currentFieldValue = searchQuery ?? (addressValues[fieldName] || '');
  const params = Object.keys(addressValues).reduce(
    (acc, field) => {
      const param = fieldNamesMap[field];
      if (field !== fieldName && addressValues[field]) {
        acc[param] = addressValues[field];
      }
      return acc;
    },
    { resultSize: 100 } as AddressSuggestionParams
  );
  params[fieldNamesMap[fieldName]] = currentFieldValue;
  return params;
};
