import type { AbstractOption } from '~shared/types/abstract-option';

const options = [
  {
    value: 'ELEMENTARY',
    title: 'Elementary',
  },
  {
    value: 'HIGHSCHOOL',
    title: 'High School',
  },
  {
    value: 'BACHELOR',
    title: 'Bachelor’s Degree',
  },
  {
    value: 'MASTER',
    title: 'Master’s Degree',
  },
  {
    value: 'DOCTORATE',
    title: 'Doctorate Degree',
  },
  {
    value: 'VOCATIONAL',
    title: 'Vocational Degree',
  },
] as const;

export type EducationLevelValue = (typeof options)[number]['value'];
export const educationLevelOptions =
  options as unknown as AbstractOption<EducationLevelValue>[];
