export const COMPANY_NAME_REQUIRED_ERROR =
  'Enter the name of the company or project you are working on';
export const SELECT_REQUIRED_ERROR = 'Select an option';
export const REFERENCE_PHONE_REQUIRED_ERROR =
  'Enter the reference’s contact number';
export const REFERENCE_NAME_REQUIRED_ERROR = 'Enter the reference’s name';
export const STREET_AND_HOUSE_REQUIRED_ERROR =
  'Enter the building number and street name';
export const NATURE_OF_WORK_OTHER_REQUIRED_ERROR = 'Enter your industry name';

export const SELECT_ONE_REQUIRED_ERROR = 'Select one option';
