import type {
  PersonFormatter,
  PersonRecovery,
  PersonScreensMapping,
} from '~entities/person';
import {
  formatPersonValuesByMapping,
  recoverPersonValuesByMapping,
} from '~entities/person';

import {
  formatSuggestion,
  recoverSuggestion,
} from '~shared/formatters/convert-suggestion';

import isEqual from '@tinkoff/utils/is/equal';

import type { FormValues } from '../types';

const mapping: PersonScreensMapping<
  Omit<FormValues, 'hasDifferentPermanentAddress'>
> = {
  country: {
    path: ['currentAddress', 'country'],
  },
  region: {
    path: ['currentAddress', 'region'],
  },
  province: {
    path: ['currentAddress', 'province'],
    format: formatSuggestion,
    recover: recoverSuggestion,
  },
  cityOrMunicipality: {
    path: ['currentAddress', 'cityOrMunicipality'],
    format: formatSuggestion,
    recover: recoverSuggestion,
  },
  barangay: {
    path: ['currentAddress', 'barangay'],
    format: formatSuggestion,
  },
  postalCode: {
    path: ['currentAddress', 'postalCode'],
  },
  streetAndHouse: {
    path: ['currentAddress', 'streetAndHouse'],
  },
  unitAndFloor: {
    path: ['currentAddress', 'unitAndFloor'],
  },
};

export const formatResidentialAddress: PersonFormatter<FormValues> = (
  application,
  screenValues
) => {
  const formattedPerson = formatPersonValuesByMapping(
    application,
    screenValues,
    mapping
  );

  if (!screenValues.hasDifferentPermanentAddress) {
    formattedPerson.permanentAddress = formattedPerson.currentAddress;
  }

  return formattedPerson;
};

export const recoverResidentialAddress: PersonRecovery<FormValues> = (
  application
) => {
  const recoveredValues = recoverPersonValuesByMapping(application, mapping);

  const { currentAddress, permanentAddress } = application;

  if (permanentAddress && !isEqual(currentAddress, permanentAddress)) {
    return { ...recoveredValues, hasDifferentPermanentAddress: true };
  }

  return recoveredValues;
};
