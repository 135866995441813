import { Col } from '@breeze-platform-ui/layout';

import styles from './problems-tooltip.module.css';

export const ProblemsTooltip: React.FC = () => (
  <Col gaps={12} alignCross="stretch">
    <p className={styles.text}>Possible reasons:</p>
    <p className={styles.text}>1. The mobile number isn’t registered yet.</p>
    <p className={styles.text}>
      2. The SIM card linked to&nbsp;this mobile number isn’t working or it’s
      installed in&nbsp;another mobile device.
    </p>
    <p className={styles.text}>3. Network or connectivity issues.</p>
  </Col>
);
