export const getAddressSuggestionsMock = {
  url: 'https://portal-api-test.breezeventures.io/delivery/api/v1/delivery/address/suggestion',
  method: 'POST',
  status: 200,
  response: {
    result: 'OK',
    traceId: '273d21aea6b477d29053127e3b9460ec',
    processingTime: '0.005s',
    payload: {
      items: [
        {
          region: 'South Luzon',
          province: 'Palawan',
          cityOrMunicipality: 'Aborlan',
        },
        {
          region: 'South Luzon',
          province: 'Occidental Mindoro',
          cityOrMunicipality: 'Abra De Ilog',
        },
        {
          region: 'North Luzon',
          province: 'Bataan',
          cityOrMunicipality: 'Abucay',
        },
        {
          region: 'North Luzon',
          province: 'Cagayan',
          cityOrMunicipality: 'Abulug',
        },
        {
          region: 'Visayas',
          province: 'Leyte',
          cityOrMunicipality: 'Abuyog',
        },
        {
          region: 'North Luzon',
          province: 'Nueva Vizcaya',
          cityOrMunicipality: 'Dupax Del Sur',
          barangay: 'Abaca',
        },
        {
          region: 'Visayas',
          province: 'Iloilo',
          cityOrMunicipality: 'San Enrique',
          barangay: 'Acacia',
        },
        {
          region: 'Metro Manila',
          province: 'Metro Manila',
          cityOrMunicipality: 'Sampaloc',
          barangay: 'Barangay 395',
        },
      ],
    },
  },
};
