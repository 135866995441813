export function getChildFields(fieldName: string, fields: string[]): string[] {
  const result: string[] = [];

  for (let i = fields.length - 1; i > 0; i--) {
    if (fieldName === fields[i]) {
      return result;
    }

    result.push(fields[i]);
  }

  return result;
}
