import { useEffect, useState, useCallback } from 'react';

export const useResendCountdown = (): [
  number,
  (countdownTime: number) => void,
  () => void
] => {
  const [timer, setTimer] = useState(0);
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (timer > 0) {
      timeoutId = setTimeout(() => {
        setTimer((time) => time - 1);
      }, 1000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timer]);

  const startCountdown = useCallback(
    (countdownTime: number) => {
      setTimer(countdownTime);
    },
    [setTimer]
  );

  const cancelCountdown = useCallback(() => {
    setTimer(0);
  }, [setTimer]);
  return [timer, startCountdown, cancelCountdown];
};
