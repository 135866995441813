import type { Validate } from 'react-hook-form';

import T from '@tinkoff/utils/function/T';
import cond from '@tinkoff/utils/function/cond';
import isBoolean from '@tinkoff/utils/is/boolean';
import isEmpty from '@tinkoff/utils/is/empty';
import isNil from '@tinkoff/utils/is/nil';
import isNumber from '@tinkoff/utils/is/number';
import isObject from '@tinkoff/utils/is/object';
import isString from '@tinkoff/utils/is/string';
import not from '@tinkoff/utils/not';
import trim from '@tinkoff/utils/string/trim';

type RequiredValidatorOptions = {
  text: string;
  strictBoolean?: boolean;
};

const isEmptyValue: (arg1: unknown) => boolean = cond([
  [isString, (value?: string) => !trim(value)],
  [isObject, (value) => isEmpty(value) || isNil(value)],
  [isNumber, Number.isNaN as (value: unknown) => boolean],
  [T, not],
]);

export function required(options: RequiredValidatorOptions): Validate<unknown> {
  return function requiredValidator(value) {
    if (options.strictBoolean && isBoolean(value)) {
      return;
    }

    if (isEmptyValue(value)) {
      return options.text;
    }
  };
}
