const getWaitingTime = (seconds: number) => {
  const secondsPart = seconds % 60;
  return `${Math.floor(seconds / 60)}:${
    secondsPart > 9 ? secondsPart : `0${secondsPart}`
  }`;
};

export const getAttemptsLimitMessage = (
  timer: number
) => `Too many attempts. You can log in with another mobile number or try again in 
${getWaitingTime(timer)}`;
