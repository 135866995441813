import type {
  PersonFormatter,
  PersonRecovery,
  PersonScreensMapping,
} from '~entities/person';
import { recoverPersonValuesByMapping } from '~entities/person';
import { formatPersonValuesByMapping } from '~entities/person';

import {
  formatSuggestion,
  recoverSuggestion,
} from '~shared/formatters/convert-suggestion';

import isEqual from '@tinkoff/utils/is/equal';

import type { FormValues } from '../types';

const mapping: PersonScreensMapping<Omit<FormValues, 'sameAsCurrent'>> = {
  country: {
    path: ['permanentAddress', 'country'],
  },
  region: {
    path: ['permanentAddress', 'region'],
  },
  province: {
    path: ['permanentAddress', 'province'],
    format: formatSuggestion,
    recover: recoverSuggestion,
  },
  cityOrMunicipality: {
    path: ['permanentAddress', 'cityOrMunicipality'],
    format: formatSuggestion,
    recover: recoverSuggestion,
  },
  barangay: {
    path: ['permanentAddress', 'barangay'],
    format: formatSuggestion,
  },
  postalCode: {
    path: ['permanentAddress', 'postalCode'],
  },
  streetAndHouse: {
    path: ['permanentAddress', 'streetAndHouse'],
  },
  unitAndFloor: {
    path: ['permanentAddress', 'unitAndFloor'],
  },
};

export const formatPermanentAddress: PersonFormatter<FormValues> = (
  person,
  screenValues
) => {
  return formatPersonValuesByMapping(person, screenValues, mapping);
};

export const recoverPermanentAddress: PersonRecovery<FormValues> = (person) => {
  const { permanentAddress, currentAddress } = person;

  if (!permanentAddress || isEqual(permanentAddress, currentAddress)) {
    return undefined;
  }

  return {
    ...recoverPersonValuesByMapping(person, mapping),
    sameAsCurrent: false,
  };
};
