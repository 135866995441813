import { BasicHeader } from '~shared/ui/basic-header';

import Clickable from '@breeze-platform-ui/clickable';
import { Int24Close } from '@breeze-platform-ui/iconsPack';

export const HeaderWithClose: React.FC<{
  onClose?: () => void;
  text?: string;
}> = ({ text, onClose }) => {
  return (
    <BasicHeader
      actionButton={
        <Clickable
          onClick={onClose}
          className="cursor-pointer border-0 bg-transparent"
        >
          <Int24Close theme={{ color: '#4D77FF', background: 'transparent' }} />
        </Clickable>
      }
      mainContent={
        <span className="text-[17px] font-bold leading-6">{text}</span>
      }
    />
  );
};
