export type RequestParams = {
  url?: string;
  version?: string;
  domain?: string;
  service?: string;
  method?: string;
  host?: string;
  headers?: Record<string, any>;
  payload?: unknown;
  withCredentials?: boolean;
  withLeadingDomain?: boolean;
  timeout?: number;
  type?: string;
  cache?: boolean;
};

export const getUrlFromParams = (params: RequestParams): string => {
  const baseSegments = [
    params.host ?? '',
    'api',
    params.version,
    params.service,
    params.method,
  ];

  baseSegments.splice(1, 0, params.domain);

  return baseSegments.join('/');
};
