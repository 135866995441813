import { environment } from '~app/environment';

import { RemoteReporter, logger } from '@tinkoff/logger';
import request from '@tinkoff/request-core';
import http from '@tinkoff/request-plugin-protocol-http';

import { getSentryReporter } from '@breeze/web-lib-sentry';
import { findOrCreateWuid } from '@breeze/web-lib-wuid';

const collectRequest = request([http()]);

const logsReporter = new RemoteReporter({
  emitLevels: {
    error: true,
    fatal: true,
  },
  async makeRequest(logObj) {
    return collectRequest({
      httpMethod: 'POST',
      type: 'json',
      url: environment.LOGS_COLLECTOR_HOST,
      payload: {
        appName: 'web-app-pos-online',
        wuid: typeof window === 'undefined' ? '' : findOrCreateWuid(),
        ...logObj,
      },
    });
  },
});

logger.setLevel('error');
logger.addReporter(logsReporter);
logger.addReporter(getSentryReporter());

export { logger } from '@tinkoff/logger';
