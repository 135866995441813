import type { Validate } from 'react-hook-form';

import isEmpty from '@tinkoff/utils/is/empty';
import isString from '@tinkoff/utils/is/string';

interface Options {
  text?: string;
  length?: number;
}

const phMobilePhoneNumberRegEx = /^\+63((9\d{9})|(89\d{8}))$/;

export function mobilePhoneFormatPh(options: Options = {}): Validate<unknown> {
  return function mobilePhoneFormatPhValidator(value) {
    const { text = 'Must be a valid Philippine mobile number' } = options;

    if (!isString(value) || isEmpty(value)) {
      return undefined;
    }

    if (!phMobilePhoneNumberRegEx.test(value)) {
      return text;
    }

    return undefined;
  };
}
