import type { Suggestion } from '~shared/types/suggestion';
import { isSuggestion } from '~shared/types/suggestion';

export function formatSuggestion(suggestion: Suggestion | string) {
  return isSuggestion(suggestion) ? suggestion.value : suggestion;
}

export function recoverSuggestion(suggestion: string): Suggestion {
  return { title: suggestion, value: suggestion };
}
