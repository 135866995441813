import type { RecoveryMapping } from '~shared/formatters';
import { formatByMapping, recoverByMapping } from '~shared/formatters';

import type { Person } from '../person';

export type PersonScreensMapping<T> = RecoveryMapping<T, Person>;

export type PersonFormatter<T> = {
  (
    person: Person,
    values: Partial<T>,
    formValues?: Record<string, unknown>
  ): Person;
};

export function formatPersonValuesByMapping<
  StepValues extends Record<string, any>
>(
  person: Person,
  stepValues: Partial<StepValues>,
  mapping: PersonScreensMapping<StepValues>
) {
  return formatByMapping(person, stepValues, mapping);
}

export type PersonRecovery<StepValues> = (
  person: Person
) => Partial<StepValues> | undefined | void;

export function recoverPersonValuesByMapping<T>(
  person: Person,
  mapping: PersonScreensMapping<T>
) {
  return recoverByMapping(person, mapping);
}
