import { environment } from '~app/environment';

import type { FormProgress } from '~entities/person';

import { stepNames, type StepName } from './step-names';

export function recoverInitialStep(
  applicationId: string,
  formProgress?: Partial<FormProgress>
): StepName | null {
  if (
    !formProgress?.lastStep ||
    formProgress.lastStep === stepNames.loanDetails ||
    formProgress.appVersion !== environment.FORM_VERSION ||
    formProgress.applicationId !== applicationId ||
    !Object.keys(stepNames).includes(formProgress.lastStep)
  ) {
    return null;
  }

  return formProgress.lastStep as StepName;
}
