import { getMaxDownPayment, getMinDownPayment } from '~entities/product';

import { phMoneyProps } from '~shared/constants/ph-money-props';

import Money from '@breeze-platform-ui/money';

export const validateDownpaymentRange = (
  downpaymentValue: number,
  totalPrice: number,
  loanParams: {
    maxLoanAmount: number;
    minLoanAmount: number;
    minDownPaymentPercent: number;
    maxDownPaymentPercent: number;
  }
): React.ReactNode => {
  const minDownPayment = getMinDownPayment(totalPrice, loanParams);
  const maxDownPayment = getMaxDownPayment(totalPrice, loanParams);

  const isDownPaymentOutOfRange =
    downpaymentValue < minDownPayment || downpaymentValue > maxDownPayment;
  const isLoanAmountOutOfRange =
    totalPrice - downpaymentValue < loanParams.minLoanAmount ||
    totalPrice - downpaymentValue > loanParams.maxLoanAmount;

  const downPaymentRangeMessage = (
    <span>Must be {getRange(minDownPayment, maxDownPayment)}</span>
  );

  if (isDownPaymentOutOfRange || isLoanAmountOutOfRange) {
    return downPaymentRangeMessage as any;
  }
};

function getRange(min: number, max: number) {
  return min === max ? (
    <Money {...phMoneyProps} value={min} showSmalls={false} />
  ) : (
    <span>
      from <Money {...phMoneyProps} value={min} showSmalls={false} /> to{' '}
      <Money {...phMoneyProps} value={max} showSmalls={false} />
    </span>
  );
}
