import type { RequestState } from '../model';

export const OTP_REQUEST_DELAY_LONG = 5 * 60 * 1000;
export const OTP_REQUEST_DELAY_SHORT = 30 * 1000;
export const OTP_REQUEST_COUNT_LIMIT = 5;

export const getOtpRequestDelaySeconds = (lastOtpRequest?: RequestState) => {
  if (!lastOtpRequest) {
    return 0;
  }

  const delayTime =
    lastOtpRequest.attemptsCount < OTP_REQUEST_COUNT_LIMIT
      ? OTP_REQUEST_DELAY_SHORT
      : OTP_REQUEST_DELAY_LONG;

  const allowedTime = lastOtpRequest.lastRequestTime + delayTime;

  const delay = allowedTime - new Date().getTime();

  if (delay <= 0) {
    return 0;
  }

  return Math.floor(delay / 1000);
};
