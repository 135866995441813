import type { AbstractOption } from '../types/abstract-option';

const options = [
  {
    value: 'yes',
    title: 'Yes',
  },
  {
    value: 'no',
    title: 'No',
  },
] as const;

export type YesNoOptionValue = (typeof options)[number]['value'];
export const yesNoOptions =
  options as unknown as AbstractOption<YesNoOptionValue>[];
